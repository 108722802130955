import classnames from "classnames";

// Styles
import "./Text.scss";

type Props = {
  children: React.ReactNode;
  className?: string;
  bold?: boolean;
  tag?: "p" | "span" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

const Text = ({ children, className, bold, tag: Tag = "p" }: Props) => {
  const baseClassName = "Text";
  const classes = classnames(
    baseClassName,
    className,
    bold && `${baseClassName}--bold`
  );

  return <Tag className={classes}>{children}</Tag>;
};

export default Text;
