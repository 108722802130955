// @ts-nocheck
import { generatePath } from "react-router";

// Order content by order key
export const OrderItemsByOrderKey = (data) => {
  if (!data) return null;

  return data.sort((a, b) => {
    if (a.order === null) return 1;
    if (b.order === null) return -1;

    return a.order - b.order;
  });
};

export const generateUrlPath = ({ path, params }) => {
  return generatePath(path, params);
};
