const prefix = "outline";
const iconName = "instagram";
const viewBox = "0 0 22 22";

const svg = (color) => (
  <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-1.000000, -1.000000)">
      <g id="instagram">
        <g>
          <path
            d="M9,22.75 C3.57000017,22.75 1.25,20.4299998 1.25,15 L1.25,9 C1.25,3.57000017 3.57000017,1.25 9,1.25 L15,1.25 C20.4299998,1.25 22.75,3.57000017 22.75,9 L22.75,15 C22.75,20.4299998 20.4299998,22.75 15,22.75 L9,22.75 Z M2.75,9 L2.75,15 C2.75,19.6100001 4.38999987,21.25 9,21.25 L15,21.25 C19.6100001,21.25 21.25,19.6100001 21.25,15 L21.25,9 C21.25,4.38999987 19.6100001,2.75 15,2.75 L9,2.75 C4.38999987,2.75 2.75,4.38999987 2.75,9 Z"
            fill={color}
          ></path>
          <path
            d="M7.75,12 C7.75,9.66000009 9.66000009,7.75 12,7.75 C14.3399999,7.75 16.25,9.66000009 16.25,12 C16.25,14.3399999 14.3399999,16.25 12,16.25 C9.66000009,16.25 7.75,14.3399999 7.75,12 Z M9.25,12 C9.25,13.52 10.48,14.75 12,14.75 C13.52,14.75 14.75,13.52 14.75,12 C14.75,10.48 13.52,9.25 12,9.25 C10.48,9.25 9.25,10.48 9.25,12 Z"
            fill={color}
          ></path>
          <path
            d="M16.6200008,7.41990423 C16.5,7.36990423 16.3900009,7.2999042 16.2900009,7.20990419 C16.2000009,7.10990419 16.1199997,6.99990427 16.0699997,6.87990427 C16.0199997,6.75990427 16,6.62990415 16,6.49990416 C16,6.36990416 16.0199997,6.23990404 16.0699997,6.11990404 C16.1299997,5.98990405 16.2000009,5.88990415 16.2900009,5.78990415 C16.3400009,5.74990415 16.3900005,5.69990425 16.4400005,5.66990425 C16.5,5.62990425 16.5600008,5.59990409 16.6200008,5.57990409 C16.6800008,5.5499041 16.7399995,5.52990416 16.8099995,5.51990416 C17.1299995,5.44990415 17.4699991,5.55990414 17.7099991,5.78990415 C17.7999991,5.88990415 17.8700001,5.98990405 17.9200001,6.11990404 C17.9700001,6.23990404 18,6.36990416 18,6.49990416 C18,6.62990415 17.9700001,6.75990427 17.9200001,6.87990427 C17.8700001,6.99990427 17.7999991,7.10990419 17.7099991,7.20990419 C17.6099991,7.2999042 17.5,7.36990423 17.3799992,7.41990423 C17.2599992,7.46990423 17.13,7.49990416 17,7.49990416 C16.87,7.49990416 16.7400008,7.46990423 16.6200008,7.41990423 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" opacity="0"></path>
        </g>
      </g>
    </g>
  </g>
);

export const instagram = {
  prefix,
  iconName, // Any name you like
  svg, // svg path data
  viewBox,
};
