// import React from "react";
// import Link, {LinkProps} from 'next/link';
import React, {
  HTMLProps,
  // PropsWithChildren,
  forwardRef,
  Children,
  cloneElement,
} from "react";
import classNames from "classnames";

// Utilities
import { isElementOfType } from "../../utils/utils";

// Components
import BodsIcon from "../icon/BodsIcon";

// Styles
import "./BodsButton.scss";

interface Props {
  small?: boolean;
  icon?: boolean;
  fill?: boolean;
}
interface ButtonProps extends Props, HTMLProps<HTMLButtonElement> {
  color?: "blue" | "gray" | "tertiary" | "white";
}
// interface LinkButtonProps extends Props, PropsWithChildren<LinkProps> {
//   download?: boolean;
// }

const BodsButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ small, icon, color = "blue", fill, children, ...rest }, ref) => {
    const iconColor = color === "blue" ? "white" : "#0F1825";

    const baseClassName = "BodsButton";
    const className = classNames(
      baseClassName,
      `${baseClassName}--${color}`,
      icon && `${baseClassName}--icon`
    );
    return (
      //@ts-ignore
      <button className={className} type="button" {...rest} ref={ref}>
        {Children.map(children as JSX.Element, (child) => {
          if (child && isElementOfType(child, BodsIcon)) {
            return cloneElement(child, { color: iconColor });
          }
          return child;
        })}
      </button>
    );
  }
);
// Button.displayName = 'Button';

// export function LinkButton({
//   small,
//   pill,
//   href,
//   primary,
//   download,
//   fill,
//   children,
//   ...rest
// }: LinkButtonProps) {
//   return (
//     <Link href={href} passHref>
//       <a
//         className={className(
//           styles.Button,
//           small && styles.small,
//           pill && styles.pill,
//           primary && styles.primary,
//           fill && styles.fill,
//         )}
//         download={download}
//         {...rest}
//       >
//         {children}
//       </a>
//     </Link>
//   );
// }

export default BodsButton;
