import classnames from "classnames";

// components
import { BodsLabelled, BodsInlineError } from "../";

// Styles
import "./BodsTextarea.scss";

type Props = {
  label?: string;
  id: string;
  name: string;
  cols?: number;
  rows?: number;
  resizeable?: boolean;
  error?: string;
};

const BodsTextarea = ({
  label,
  id,
  name,
  cols = 30,
  rows = 5,
  resizeable = true,
  error,
  ...rest
}: Props) => {
  const baseClassName = "BodsTextarea";
  const classes = classnames(
    baseClassName,
    resizeable ? `${baseClassName}--resizeable` : ""
  );

  return (
    <BodsLabelled id={id} label={label ? label : name}>
      <textarea
        name={name}
        id={id}
        cols={cols}
        rows={rows}
        className={classes}
        {...rest}
      />
      {error && <BodsInlineError fieldID={id} message={error} />}
    </BodsLabelled>
  );
};

export default BodsTextarea;
