import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Images
import LogoImage from "../../images/logo-full.svg";
import LogoImgeSlim from "../../images/logo.svg";

// Hooks
import { useToggle } from "../../hooks/use-toggle";
import useWindowDimensions from "../../design_system/hooks/use-window-dimensions";

// Components
import {
  Container,
  Row,
  Column,
  BodsIcon,
  BodsButton,
  BodsDrawer,
} from "../../design_system/components";
import Navigation from "../Navigation/Navigation";
import SocialLinks from "../SocialLinks/SocialLinks";
import NavigationMobile from "../Navigation/NavigationMobile";

// Styles
import "./Header.scss";

type Props = {
  showNavigation?: boolean;
};

const Header = ({ showNavigation = true }: Props) => {
  const [offset, setOffset] = useState(0);
  const [isMobileDrawerOpen, toggleIsMobileDrawerOpen] = useToggle(false);

  const { width } = useWindowDimensions();

  const onLogoClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  useEffect(() => {
    if (width > 991 && isMobileDrawerOpen) {
      toggleIsMobileDrawerOpen();
    }
  }, [width, isMobileDrawerOpen, toggleIsMobileDrawerOpen]);

  const showSlim = offset > 276;

  const baseClassName = "Header";

  const Content = ({ slim }: { slim?: boolean }) => {
    const logo = slim ? LogoImgeSlim : LogoImage;
    return (
      <Container>
        <Row className={baseClassName}>
          <Column md="6" lg="3" xl="3">
            <Link to="/" onClick={onLogoClick}>
              <img src={logo} className={`${baseClassName}__logo__image`} alt="Blue Oak Brewing"/>
            </Link>
          </Column>
          <Column md="hidden" lg="6" xl="6">
            {showNavigation && (
              <div className={`${baseClassName}__navigation`}>
                <Navigation />
              </div>
            )}
          </Column>
          <Column md="hidden" lg="3" xl="3">
            <div className={`${baseClassName}__social`}>
              <SocialLinks />
            </div>
          </Column>
          <Column md="6" lg="hidden" xl="hidden">
            <div className={`${baseClassName}__mobile-navigation`}>
              <BodsButton
                icon
                color="tertiary"
                onClick={toggleIsMobileDrawerOpen}
              >
                <BodsIcon name="menu" />
              </BodsButton>
            </div>
          </Column>
        </Row>
      </Container>
    );
  };

  return (
    <>
      {showSlim && (
        <Container fluid className={`${baseClassName}--slim-wrapper`}>
          <Content slim />
        </Container>
      )}
      <Content />
      <BodsDrawer
        isOpen={isMobileDrawerOpen}
        toggleIsOpen={toggleIsMobileDrawerOpen}
      >
        <div className={`${baseClassName}__mobile-menu`}>
          <NavigationMobile onClick={toggleIsMobileDrawerOpen} />
          <div className={`${baseClassName}__mobile-menu__social`}>
            <SocialLinks size="40" />
          </div>
        </div>
      </BodsDrawer>
    </>
  );
};

export default Header;
