import classnames from "classnames";

// Styles
import "./BodsCardBody.scss";

type PaddingType = "0" | "0.25" | "0.50" | "0.75" | "1";

type Props = {
  children?: React.ReactNode | React.ReactElement | React.ReactElement[];
  className?: string;
  padding?: PaddingType;
};

const BodsCardBody = ({
  children,
  className,
  padding = "1",
}: Props) => {
  const baseClassName = "BodsCardBody";
  const classes = classnames(
    baseClassName,
    { [`${baseClassName}--padding-0`]: padding === "0" },
    { [`${baseClassName}--padding-0-25`]: padding === "0.25" },
    { [`${baseClassName}--padding-0-50`]: padding === "0.50" },
    { [`${baseClassName}--padding-0-75`]: padding === "0.75" },
    { [`${baseClassName}--padding-1`]: padding === "1" },
    // noPadding && `${baseClassName}--no-padding`,
    className
  );

  return <div className={classes}>{children}</div>;
};

export default BodsCardBody;
