import { gql } from "graphql-request";

export const pagesCollection = gql`
  {
    pagesCollection {
      items {
        name
        path
        showTitle
        headerImage {
          url
        }
        photoGalleryCollection(limit: 50) {
          items {
            url
            width
            height
            sys {
              id
            }
          }
        }
        faqsCollection(limit: 50) {
          items {
            title
            text {
              json
            }
            sys {
              id
            }
          }
        }
        content {
          json
        }
        sys {
          id
        }
      }
    }
  }
`;
