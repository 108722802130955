import classnames from "classnames";

// Styles
import "./Row.scss";

type Props = {
  children: React.ReactNode;
  className?: string;
  center?: boolean;
};

const Row = ({ children, className, center = false }: Props) => {
  const baseClassName = "Row";
  const classes = classnames(
    baseClassName,
    center && `${baseClassName}--center`,
    className
  );

  return <div className={classes}>{children}</div>;
};

export default Row;
