const prefix = "outline";
const iconName = "untappd";
const viewBox = "0 0 22 22";

const svg = (color) => (
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-1.000000, -1.000000)">
      <g>
        <g mask="url(#mask-2)">
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z"></path>
          <path
            d="M9,22.75 C3.57000017,22.75 1.25,20.4299998 1.25,15 L1.25,9 C1.25,3.57000017 3.57000017,1.25 9,1.25 L15,1.25 C20.4299998,1.25 22.75,3.57000017 22.75,9 L22.75,15 C22.75,20.4299998 20.4299998,22.75 15,22.75 L9,22.75 Z M2.75,9 L2.75,15 C2.75,19.6100001 4.38999987,21.25 9,21.25 L15,21.25 C19.6100001,21.25 21.25,19.6100001 21.25,15 L21.25,9 C21.25,4.38999987 19.6100001,2.75 15,2.75 L9,2.75 C4.38999987,2.75 2.75,4.38999987 2.75,9 Z"
            fill={color}
          ></path>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" opacity="0"></path>
          <g
            transform="translate(4.000000, 5.000000)"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          >
            <path
              d="M15.4957543,0.816834275 C15.4603453,0.703898821 15.3486767,0.496462586 15.0135944,0.262722771 C14.6758679,0.0316798115 14.4388884,-0.00245573289 14.3163366,7.10542736e-15 C14.2644877,7.10542736e-15 14.2236371,0.0447577148 14.2291554,0.0946680183 L14.2400387,0.21547238 C14.2482011,0.310010105 14.150175,0.317879013 14.0683971,0.336276741 C13.9975409,0.35467447 13.9975409,0.420322589 13.9702944,0.546372889 C13.5984618,0.908010166 13.2698558,1.23093094 12.9786515,1.51986394 C12.424984,2.06920977 12.0069742,2.49516521 11.6858408,2.82831925 C10.6182833,3.93591422 10.6220388,4.01748488 10.2766481,4.19385186 L9.47848998,4.60089236 C9.1842966,4.75058633 8.93095384,4.96330545 8.73758406,5.22323801 L4.68164727,10.6827455 C4.47459675,10.9610758 4.4419086,11.3286978 4.5971869,11.6385776 C5.07386678,12.5918607 6.09540029,13.3060842 7.19311675,13.4373435 C7.54724479,13.4794218 7.89593119,13.3323139 8.10294338,13.0539097 L12.1589185,7.59717302 C12.3523266,7.33716656 12.4803584,7.03515566 12.5321306,6.72006685 L12.6737665,5.85873545 C12.712816,5.62244655 12.7596831,5.5264311 12.9786515,5.14824325 C13.1930598,4.77792432 13.5724799,4.13692234 14.2714239,2.82828231 C14.5007775,2.39885421 14.7644287,1.89771929 15.0680491,1.31050665 C15.1824768,1.24485854 15.2478531,1.22383784 15.2451323,1.15032081 C15.2396524,1.07155784 15.2152033,0.979606145 15.3077878,0.955999421 L15.4303013,0.927109816 C15.4794293,0.913994969 15.5120792,0.864084666 15.4957543,0.816834275 Z"
              transform="translate(10.000000, 6.722222) scale(-1, 1) translate(-10.000000, -6.722222) "
            ></path>
            <path
              d="M10.9957543,0.816834275 C10.9603453,0.703898821 10.8486767,0.496462586 10.5135944,0.262722771 C10.1758679,0.0316798115 9.93888841,-0.00245573289 9.81633658,7.10542736e-15 C9.76448773,7.10542736e-15 9.72363712,0.0447577148 9.7291554,0.0946680183 L9.74003868,0.21547238 C9.74820114,0.310010105 9.650175,0.317879013 9.56839714,0.336276741 C9.49754087,0.35467447 9.49754087,0.420322589 9.47029436,0.546372889 C9.09846183,0.908010166 8.76985584,1.23093094 8.47865153,1.51986394 C7.92498397,2.06920977 7.50697416,2.49516521 7.18584084,2.82831925 C6.11828333,3.93591422 6.12203882,4.01748488 5.77664806,4.19385186 L4.97848998,4.60089236 C4.6842966,4.75058633 4.43095384,4.96330545 4.23758406,5.22323801 L0.181647266,10.6827455 C-0.0254032493,10.9610758 -0.0580914021,11.3286978 0.0971869038,11.6385776 C0.573866777,12.5918607 1.59540029,13.3060842 2.69311675,13.4373435 C3.04724479,13.4794218 3.39593119,13.3323139 3.60294338,13.0539097 L7.6589185,7.59717302 C7.8523266,7.33716656 7.98035839,7.03515566 8.0321306,6.72006685 L8.17376649,5.85873545 C8.21281599,5.62244655 8.25968306,5.5264311 8.47865153,5.14824325 C8.69305976,4.77792432 9.07247992,4.13692234 9.7714239,2.82828231 C10.0007775,2.39885421 10.2644287,1.89771929 10.5680491,1.31050665 C10.6824768,1.24485854 10.7478531,1.22383784 10.7451323,1.15032081 C10.7396524,1.07155784 10.7152033,0.979606145 10.8077878,0.955999421 L10.9303013,0.927109816 C10.9794293,0.913994969 11.0120792,0.864084666 10.9957543,0.816834275 Z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </g>
);

export const untappd = {
  prefix,
  iconName, // Any name you like
  svg, // svg path data
  viewBox,
};
