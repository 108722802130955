import {
  Children,
  cloneElement,
  useEffect,
  useState,
  useCallback,
} from "react";
import classnames from "classnames";

import { isElementOfType } from "../../utils/utils";

import { Portal } from "../portal/Portal";
import BodsBackdrop from "../backdrop/BodsBackdrop";
import BodsModalHeader from "./BodsModalHeader";
import BodsModalBody from "./BodsModalBody";

import "./BodsModal.scss";

export type Size = "full" | "lg" | "md" | "sm";
const DEFAULT_SIZE = "md";

export interface Props {
  /**
   * Size of modal, currenly only Full page is supported.
   * @default 'lg'
   */
  size?: Size;
  isOpen: boolean;
  // children?: JSX.Element;
  children?: React.ReactElement | React.ReactElement[];
  innerClassName?: string;
  onToggle?(): void;
  qaTest?: string;
  dismissOnBackdrop?: boolean;
  hideBackdrop?: boolean;
  animation?: boolean;
  // to be used sparingly - but to allow modals on modals on portals, etc
  zIndex?: number;
}

const classNamePrefix = "BodsModal";
const bodyClassNamePrefix = "Body--BodsModal-opened";

const BodsModal: React.FC<Props> = ({
  size = DEFAULT_SIZE,
  isOpen = false,
  children,
  onToggle,
  qaTest,
  innerClassName,
  dismissOnBackdrop = true,
  hideBackdrop = false,
  animation = true,
  zIndex,
}: Props) => {
  const [isModalRender, setIsModalRender] = useState(!animation);

  const classes = classnames(
    classNamePrefix,
    `${classNamePrefix}--size-${size}`,
    {
      [`${classNamePrefix}--open`]: isModalRender,
    }
  );

  const contentClasses = classnames(
    `${classNamePrefix}__content`,
    `${classNamePrefix}__content--size-${size}`,
    innerClassName
  );

  const removeStylesFromBody = useCallback(() => {
    document.body.classList.remove(bodyClassNamePrefix);
    document.body.style.paddingRight = "unset";
  }, []);

  const addStylesToBody = useCallback(() => {
    const prevWidth = document.body.clientWidth;
    document.body.classList.add(bodyClassNamePrefix);
    const currentWidth = document.body.clientWidth;
    document.body.style.paddingRight = `${currentWidth - prevWidth}px`;
  }, []);

  useEffect(() => {
    if (animation) setTimeout(() => setIsModalRender(isOpen));
    if (isOpen) addStylesToBody();
    else removeStylesFromBody();

    return removeStylesFromBody;
  }, [isOpen, animation, addStylesToBody, removeStylesFromBody]);

  if (!isOpen) return null;

  // const sanitizedChildren = children;
  // const sanitizedChildren = children && sanitizeFragments(children);

  return (
    <Portal idPrefix="modal">
      <div className={classes} data-test={qaTest} style={{ zIndex }}>
        <div className={contentClasses}>
          {Children.map(children, (child) => {
            if (child && isElementOfType(child, BodsModalHeader)) {
              return cloneElement(child, { onToggle, qaTest });
            }
            if (child && isElementOfType(child, BodsModalBody)) {
              return cloneElement(child, { size: size });
            }
            return child;
          })}
        </div>
        {!hideBackdrop && (
          <BodsBackdrop
            animation={animation}
            onClick={dismissOnBackdrop ? onToggle : undefined}
          />
        )}
      </div>
    </Portal>
  );
};

export default BodsModal;
