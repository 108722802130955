// @ts-nocheck
import { useQuery } from "@tanstack/react-query";

// GQL
import { gqlClient } from "../gql/gqlClient";
import { bannerCollection } from "../gql/bannerCollection.query";

export const useBanner = () => {
  const { isLoading, data = [] } = useQuery(["banner"], () =>
    gqlClient
      .request(bannerCollection)
      .then((data) => data.bannerCollection.items)
  );

  const hasBanner = data?.length > 0;
  return { isLoading, hasBanner, banner: data[0] };
};
