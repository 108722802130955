// @ts-nocheck
import { useQuery } from "@tanstack/react-query";

// GQL
import { gqlClient } from "../gql/gqlClient";
import { eventCalendarCollection } from "../gql/eventCalendarCollection.query";

// Types
import { EventType } from "../types";

export const useEventsCalendar = () => {
  const { isLoading, data }: { isLoading: boolean; data: EventType[] } =
    useQuery(["eventsCalendar"], () =>
      gqlClient.request(eventCalendarCollection).then((data) =>
        data.eventCalendarCollection.items.map((item) => {
          return {
            title: item.event.title,
            shortDescription: item.event.shortDescription,
            description: item.event.description,
            time: item.time || item.event.time,
            websiteLink: item.event.websiteLink,
            date: item.date,
            sys: { id: item.sys.id },
            image: { url: item.event.image?.url },
          };
        })
      )
    );

  return { isLoading, data };
};
