// @ts-nocheck
// import PhotoAlbum from "react-photo-album";

// Hooks
import { usePages } from "../../hooks/use-pages";

// components
import {
  Container,
  Row,
  Column,
  ContentfulRichTextRenderer,
  Text,
  PhotoGallery,
  BodsCard,
  BodsCardBody,
  BodsButton,
} from "../../design_system/components";
import BodsImageCard from "../../design_system/components/image-card/BodsImageCard";
import FaqList from "../FaqList/FaqList";
import PageTitle from "../PageTitle/PageTitle";

// Styles
import "./PrivateEventsPage.scss";

const PrivateEventsPage = () => {
  const { isLoading, page, faqs, photos, name, showName } = usePages({
    path: "private-events",
  });

  const hasHeaderImage = page?.headerImage;

  const handleReadyClick = () => {
    const element = document.createElement("a");
    element.href = "https://blueoakbrewingco.tripleseat.com/party_request/37457";
    element.target = "_blank";
    element.rel = "noopener noreferrer";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const baseClassName = "PrivateEventsPage";

  return (
    <div className={baseClassName}>
      {hasHeaderImage && (
        <Container>
          <Row>
            <Column size={12}>
              <BodsImageCard image={page.headerImage?.url}>
                <div style={{ padding: "150px 0" }}></div>
              </BodsImageCard>
            </Column>
          </Row>
        </Container>
      )}

      {showName && <PageTitle title={name} />}
      {isLoading ? (
        <>Loading...</>
      ) : (
        <div>
          <Container paddingY={3} className={`${baseClassName}__job-list`}>
            <Row>
              <Column md="12" lg="12" xl="12">
                <ContentfulRichTextRenderer data={page?.content} />
              </Column>
            </Row>
          </Container>

          <Container paddingY={3} className={`${baseClassName}__job-list`}>
            <Row>
              <Column md="12" lg="12" xl="12">
                <PhotoGallery photos={photos} />
              </Column>
            </Row>
          </Container>

          <Container paddingY={3} className={`${baseClassName}__faqs`}>
            <Row>
              <Column size={12}>
                <Text tag="h1" className={`${baseClassName}__faqs__title`}>
                  FAQ
                </Text>
              </Column>
            </Row>
            <Row>
              <Column sm="hidden" md="2" lg="2" xl="2" />
              {isLoading ? (
                <>Loading...</>
              ) : (
                <Column sm="12" md="8" lg="8" xl="8">
                  <div className={`${baseClassName}__faqs__list`}>
                    <FaqList faqs={faqs} />
                  </div>
                </Column>
              )}
              <Column sm="hidden" md="2" lg="2" xl="2" />
            </Row>
          </Container>

          <Container paddingY={3} className={`${baseClassName}__job-list`}>
            <Row>
              <Column md="12" lg="12" xl="12">
                <BodsCard className={`${baseClassName}__ready`}>
                  <BodsCardBody className={`${baseClassName}__ready__body`}>
                    <Text tag="h3" bold>
                      Ready to schedule your event with us?
                    </Text>
                    <Text>
                      Let us know a little bit about your event, and we'll help
                      you with the perfect space with the best beer in town!
                    </Text>
                    <BodsButton onClick={handleReadyClick}>
                      Get Started
                    </BodsButton>
                  </BodsCardBody>
                </BodsCard>
              </Column>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default PrivateEventsPage;
