import classnames from "classnames";

// Styles
import "./Container.scss";

type Props = {
  children: React.ReactNode;
  className?: string;
  fluid?: boolean;
  paddingY?: 0 | 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4;
};

const Container = ({
  children,
  className,
  fluid = false,
  paddingY = 0,
}: Props) => {
  const baseClassName = "Container";
  const classes = classnames(
    baseClassName,
    `${baseClassName}--paddingY-${paddingY.toString().replace(".", "-")}`,
    className,
    fluid && `${baseClassName}--fluid`
  );

  return <div className={classes}>{children}</div>;
};

export default Container;
