// Styles
import "./BodsLabelled.scss";

export interface Props {
  /** A unique identifier for the label */
  id: string;
  /** Text for the label */
  label: string;
  /** Error to display beneath the label */
  error?: string;
  /** Content to display inside the connected */
  children?: React.ReactNode;
}

const BodsLabelled = ({ id, label, error, children }: Props) => {
  // const errorMarkup = error && <span>error message</span>;

  // const labelMarkup = label ? (
  //   <WpLabel id={id} {...rest} hidden={labelHidden} showRequiredIndicator={showRequiredIndicator}>
  //     {label}
  //   </WpLabel>
  // ) : null;
  const baseClassName = "BodsLabelled";
  return (
    <div className={baseClassName}>
      {label && (
        <label htmlFor={id} className={`${baseClassName}__label`}>
          {label}
        </label>
      )}
      {children}
      {/* {errorMarkup} */}
    </div>
  );
};

export default BodsLabelled;
