// Utils
import { formatDate } from "../../utils/DateTime.utils";

// Styles
import './DayBlock.scss';

type Props = {
  date: string;
};
const DayBlock = ({ date }: Props) => {
  const day = formatDate(date, "D");
  const month = formatDate(date, "MMM");

  const baseClassName = "DayBlock";
  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__month`}>{month}</div>
      <div className={`${baseClassName}__day`}>{day}</div>
    </div>
  );
};

export default DayBlock;
