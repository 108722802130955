import { useParams } from "react-router-dom";

// Types
import { EventType } from "../../types";

// Hooks
import { useEventsCalendar } from "../../hooks/use-events-calendar";

// Components
import { Container, Row } from "../../design_system/components";
import EventDetails from "../EventDetails/EventDetails";

// Styles
import "./EventDetailsPage.scss";

const EventDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, data = [] } = useEventsCalendar();

  const event = data && data.find((e: EventType) => e?.sys?.id === id);

  return (
    <Container>
      {isLoading ? (
        <>Loading...</>
      ) : (
        <>
          {event ? (
            <Row>
              <EventDetails event={event} />
            </Row>
          ) : (
            <>Event Not found</>
          )}
        </>
      )}
    </Container>
  );
};

export default EventDetailsPage;
