// <?xml version="1.0" encoding="UTF-8"?>
// <svg width="9px" height="18px" viewBox="0 0 9 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
//     <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
//     <title>vuesax/outline/arrow-left</title>
//     <desc>Created with Sketch.</desc>
//     <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
//         <g id="vuesax/outline/arrow-left" transform="translate(-7.000000, -3.000000)">
//             <g id="arrow-left">
//                 <g>
//                     <path d="M14.4692974,20.4500198 L7.94929683,13.9300203 C6.88929689,12.8700204 6.88929689,11.1300192 7.94929683,10.0700192 L14.4692974,3.55001953 C14.7592974,3.26001954 15.2392969,3.26001954 15.5292969,3.55001953 C15.8192969,3.84001952 15.8192969,4.32001969 15.5292969,4.61001968 L9.00929725,11.1300197 C8.52929726,11.6100197 8.52929726,12.3900199 9.00929725,12.8700199 L15.5292969,19.3900185 C15.8192969,19.6800185 15.8192969,20.1600198 15.5292969,20.4500198 C15.3792969,20.5900198 15.1892971,20.6700191 14.9992971,20.6700191 C14.8092971,20.6700191 14.6192974,20.6000198 14.4692974,20.4500198 Z" id="Vector" fill="#292D32" fill-rule="nonzero"></path>
//                     <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" id="Vector" opacity="0"></path>
//                 </g>
//             </g>
//         </g>
//     </g>
// </svg>

const prefix = "outline";
const iconName = "chevron-left";
const viewBox = "0 0 9 18";

const svg = (color) => (
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-7.000000, -3.000000)">
      <g>
        <g>
          <path
            d="M14.4692974,20.4500198 L7.94929683,13.9300203 C6.88929689,12.8700204 6.88929689,11.1300192 7.94929683,10.0700192 L14.4692974,3.55001953 C14.7592974,3.26001954 15.2392969,3.26001954 15.5292969,3.55001953 C15.8192969,3.84001952 15.8192969,4.32001969 15.5292969,4.61001968 L9.00929725,11.1300197 C8.52929726,11.6100197 8.52929726,12.3900199 9.00929725,12.8700199 L15.5292969,19.3900185 C15.8192969,19.6800185 15.8192969,20.1600198 15.5292969,20.4500198 C15.3792969,20.5900198 15.1892971,20.6700191 14.9992971,20.6700191 C14.8092971,20.6700191 14.6192974,20.6000198 14.4692974,20.4500198 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" opacity="0"></path>
        </g>
      </g>
    </g>
  </g>
);

export const chevronLeft = {
  prefix,
  iconName, // Any name you like
  svg, // svg path data
  viewBox,
};
