import { Route } from "react-router-dom";

// Components
import PageLayout from "../Layouts/PageLayout";

type Props = {
  path: string | string[];
  exact?: boolean;
  component: Function;
  layout?: Function;
  // All other props
  [x: string]: any;
};

const RouteWrapper = ({
  path,
  exact,
  component: Component,
  layout: Layout = PageLayout,
  ...rest
}: Props) => {
  return (
    <Route
      path={path}
      exact={exact}
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default RouteWrapper;
