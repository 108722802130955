const prefix = "outline";
const iconName = "close";
const viewBox = "0 0 24 24";

const svg = (color) => (
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="close">
      <g>
        <path
          d="M3.11111111,13.2 C2.50370371,13.2 2,12.7013333 2,12.1 C2,11.4986667 2.50370371,11 3.11111111,11 L20.8888889,11 C21.4962963,11 22,11.4986667 22,12.1 C22,12.7013333 21.4962963,13.2 20.8888889,13.2 L3.11111111,13.2 Z"
          fill={color}
          fillRule="nonzero"
          transform="translate(12.000000, 12.100000) rotate(45.000000) translate(-12.000000, -12.100000) "
        ></path>
        <path
          d="M11,20.8888889 L11,3.11111111 C11,2.50370371 11.4533333,2 12,2 C12.5466667,2 13,2.50370371 13,3.11111111 L13,20.8888889 C13,21.4962963 12.5466667,22 12,22 C11.4533333,22 11,21.4962963 11,20.8888889 Z"
          fill={color}
          fillRule="nonzero"
          transform="translate(12.000000, 12.000000) rotate(45.000000) translate(-12.000000, -12.000000) "
        ></path>
        <path
          d="M24,0 L24,24 L-2.27373675e-13,24 L-2.27373675e-13,0 L24,0 Z"
          opacity="0"
        ></path>
      </g>
    </g>
  </g>
);

export const close = {
  prefix,
  iconName, // Any name you like
  svg, // svg path data
  viewBox,
};
