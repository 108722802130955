import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Images
import HeroImage from "../../images/brewery_3.png";
import ToGoBeer from "../../images/brewery_4.png";
import Sweatshirt from "../../images/sweatshirt.png";
import GiftCardImg from "../../images/gift_cards_2.png";
import FlightImg from "../../images/flight_1.png";
import TaproomImg from "../../images/taproom_1.png";
import GlassImg from "../../images/glass_4.png";

// Hooks
import { useEventsCalendar } from "../../hooks/use-events-calendar";
import { useFoodTruckCalendar } from "../../hooks/use-food-truck-calendar";

// Types
import { MenuItemType } from "../../types";

// Actions
import { getMenu } from ".././../actions/menu.actions";

// Components
import {
  Container,
  Row,
  Column,
  Text,
  InfoCard,
  BodsButton,
  BodsImageCard,
} from "../../design_system/components";
import AddressInfoCard from "../AddressInfoCard/AddressInfoCard";
import HoursInfoCard from "../HoursInfoCard/HoursInfoCard";
import EventCardCarousel from "../EventCardCarousel/EventCardCarousel";
import HeroCarousel from "../HeroCarousel/HeroCarousel";
import EventListItem from "../EventList/EventListItem";
import BeerList from "../BeerList/BeerList";

// Styles
import "./HomePage.scss";
import { Routes } from "../../constants/Routes.constants";

const HomePage = () => {
  const { data: events = [] } = useEventsCalendar();
  const [menu, setMenu] = useState<MenuItemType[]>();
  const { data: foodTrucks = [] } = useFoodTruckCalendar();

  useEffect(() => {
    getMenu()
      .then((response: MenuItemType[]) => {
        setMenu(response.filter((item) => item.onTap));
      })
      .catch(() => {
        console.log("-- error fetching menu");
      });
  }, []);

  const baseClassName = "HomePage";

  const FeatureCard = ({
    title,
    image,
    to,
    btnText,
  }: {
    title: string;
    image: string;
    to: string;
    btnText?: string;
  }) => {
    return (
      <div className={`${baseClassName}__feature-card`}>
        <BodsImageCard image={image} hover>
          <Link to={{ pathname: to }} target="_blank" rel="noopener noreferrer">
            <div className={`${baseClassName}__feature-card__content`}>
              <Text
                tag="h1"
                className={`${baseClassName}__feature-card__content__title`}
              >
                {title}
              </Text>
              {btnText && (
                <div
                  className={`${baseClassName}__feature-card__content__button`}
                >
                  <BodsButton color="white">{btnText}</BodsButton>
                </div>
              )}
            </div>
          </Link>
        </BodsImageCard>
      </div>
    );
  };

  return (
    <div className={baseClassName}>
      <Container>
        <Row>
          <Column size={12}>
            <HeroCarousel
              images={[HeroImage, GlassImg, TaproomImg, FlightImg]}
            />
          </Column>
        </Row>
      </Container>
      <Container paddingY={2}>
        <Row>
          <Column md="12" lg="4" className={`${baseClassName}__info-card`}>
            <AddressInfoCard center />
          </Column>
          <Column md="12" lg="4" className={`${baseClassName}__info-card`}>
            <HoursInfoCard center />
          </Column>
          <Column md="12" lg="4" className={`${baseClassName}__info-card`}>
            <InfoCard title="Next Food Truck" center>
              <div style={{ paddingTop: "1rem" }}>
                {foodTrucks.length > 0 && (
                  <EventListItem event={foodTrucks[0]} />
                )}
              </div>
              <Link
                to={Routes.FOOD_TRUCK_PAGE.path}
                style={{ paddingTop: "2rem" }}
              >
                See More
              </Link>
            </InfoCard>
          </Column>
        </Row>
      </Container>
      <Container paddingY={2} className={`${baseClassName}__on-tap`}>
        <Row>
          <Column sm="hidden" md="2" lg="2" xl="2" />
          <Column sm="12" md="8" lg="8" xl="8">
            <h1>On Tap</h1>
            {!menu ? <>loading</> : <BeerList menu={menu} />}
          </Column>
          <Column sm="hidden" md="2" lg="2" xl="2" />
        </Row>
      </Container>
      {events.length > 0 && (
        <Container fluid paddingY={4} className={`${baseClassName}__events`}>
          <Text tag="h3" className={`${baseClassName}__events__title`}>
            Upcoming Events
          </Text>
          <Container>
            <EventCardCarousel events={events} />
          </Container>
        </Container>
      )}
      <Container paddingY={4}>
        <Row>
          <Column sm="12" md="4" className={`${baseClassName}__feature-column`}>
            <FeatureCard
              title="to-go beer"
              image={ToGoBeer}
              to="https://store.blueoakbrewing.com/"
              btnText="Order now"
            />
          </Column>
          <Column sm="12" md="4" className={`${baseClassName}__feature-column`}>
            <FeatureCard
              title="Gift Cards"
              image={GiftCardImg}
              to="https://squareup.com/gift/AHZ2514AB4XZY/order"
              btnText="Purchase"
            />
          </Column>
          <Column sm="12" md="4" className={`${baseClassName}__feature-column`}>
            <FeatureCard
              title="Merch"
              image={Sweatshirt}
              to="https://store.blueoakbrewing.com/?location=11ea66f451710c41b0c80cc47a2ae378#4"
              btnText="Purchase"
            />
          </Column>
        </Row>
      </Container>
      {/* <Container fluid>
        <Row>
          <Column size={2}>
            <BodsImageCard image={Glass1}>
              <div style={{ padding: "150px 0" }}></div>
            </BodsImageCard>
          </Column>
          <Column size={2}>
            <BodsImageCard image={Glass1}>
              <div style={{ padding: "150px 0" }}></div>
            </BodsImageCard>
          </Column>
          <Column size={2}>
            <BodsImageCard image={Glass1}>
              <div style={{ padding: "150px 0" }}></div>
            </BodsImageCard>
          </Column>
          <Column size={2}>
            <BodsImageCard image={Glass1}>
              <div style={{ padding: "150px 0" }}></div>
            </BodsImageCard>
          </Column>
          <Column size={2}>
            <BodsImageCard image={Glass1}>
              <div style={{ padding: "150px 0" }}></div>
            </BodsImageCard>
          </Column>
          <Column size={2}>
            <BodsImageCard image={Glass1}>
              <div style={{ padding: "150px 0" }}></div>
            </BodsImageCard>
          </Column>
        </Row>
      </Container> */}
    </div>
  );
};

export default HomePage;
