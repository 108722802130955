import useWindowDimensions from "./use-window-dimensions";

const DEFAULT_SMALL_BREAKPOINT = 576;
const DEFAULT_MEDIUM_BREAKPOINT = 768;
const DEFAULT_LARGE_BREAKPOINT = 992;
const DEFAULT_XTRA_LARGE_BREAKPOINT = 1200;

interface Props {
  isAtLeast?: boolean;
  isAtMost?: boolean;
  breakpoints?: {
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}

export const useResponsiveScreenWidth = ({
  isAtLeast,
  isAtMost,
  breakpoints,
}: Partial<Props> = {}) => {
  const { width: windowWidth } = useWindowDimensions();

  const _breakpoints = {
    sm: breakpoints?.sm || DEFAULT_SMALL_BREAKPOINT,
    md: breakpoints?.md || DEFAULT_MEDIUM_BREAKPOINT,
    lg: breakpoints?.lg || DEFAULT_LARGE_BREAKPOINT,
    xl: breakpoints?.xl || DEFAULT_XTRA_LARGE_BREAKPOINT,
  };

  let isXtraSmall = windowWidth < _breakpoints.sm;
  let isSmall = windowWidth >= _breakpoints.sm && windowWidth < _breakpoints.md;
  let isMedium =
    windowWidth >= _breakpoints.md && windowWidth < _breakpoints.lg;
  let isLarge = windowWidth >= _breakpoints.lg && windowWidth < _breakpoints.xl;
  let isXtraLarge = windowWidth >= _breakpoints.xl;

  if (isAtMost) {
    isXtraSmall = windowWidth <= _breakpoints.sm;
    isSmall = windowWidth <= _breakpoints.md;
    isMedium = windowWidth <= _breakpoints.lg;
    isLarge = windowWidth <= _breakpoints.xl;
    isXtraLarge = windowWidth > _breakpoints.xl;
  }

  if (isAtLeast) {
    isXtraSmall = windowWidth > _breakpoints.sm;
    isSmall = windowWidth >= _breakpoints.sm;
    isMedium = windowWidth >= _breakpoints.md;
    isLarge = windowWidth >= _breakpoints.lg;
    isXtraLarge = windowWidth >= _breakpoints.xl;
  }

  return { isXtraSmall, isSmall, isMedium, isLarge, isXtraLarge, windowWidth };
};
