import { useEffect } from "react";
import { useLocation } from "react-router";
import ReactGA from "react-ga4";

const GoogleAnalytics = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID}`);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `${pathname}${search}` });
  }, [pathname, search]);

  return null;
};

export default GoogleAnalytics;
