// Types
import { JobType } from "../../types/Job.type";

// Utils
import { OrderItemsByOrderKey } from "../../utils/utils";

// Components
import JobsListItem from "./JobsListItem";

type Props = {
  jobs: JobType[];
};

const JobsList = ({ jobs }: Props) => {
  if (!jobs || jobs?.length === 0) return null;

  const ordered: JobType[] = OrderItemsByOrderKey(jobs);

  const baseClassName = "JobsList";

  return (
    <div className={baseClassName}>
      {ordered.map((job) => (
        <JobsListItem job={job} key={job.sys.id} />
      ))}
    </div>
  );
};

export default JobsList;
