// @ts-nocheck
import { useQuery } from "@tanstack/react-query";

// GQL
import { gqlClient } from "../gql/gqlClient";
import { jobsCollection } from "../gql/jobsCollection.query";

export const useJobs = () => {
  return useQuery(["jobs"], () =>
    gqlClient.request(jobsCollection).then((data) => data.jobsCollection.items)
  );
};
