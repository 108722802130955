import { gql } from "graphql-request";

// Utils
import { getCurrentMoment } from "../utils/DateTime.utils";

export const eventCalendarCollection = gql`
  {
    eventCalendarCollection(
      where: {date_gt: "${getCurrentMoment()
        .subtract(1, "days")
        .startOf("day")
        .toISOString()}"},
      order: [date_ASC]
      ) {
      items {
        date
        time
        event {
          title
          date
          time
          websiteLink
          description {
            json
          }
          shortDescription
          image {
            url
          }
          sys {
            id
          }
        }
        sys {
          id
        }
      }
    }
  }
`;
