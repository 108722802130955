// Components
import { InfoCard, Text } from "../../design_system/components";

// Constants
import { Routes } from "../../constants/Routes.constants";

type Props = {
  title?: string;
  center?: boolean;
};

const HoursInfoCard = ({ title = "Hours", center = false }: Props) => {
  return (
    <InfoCard title={title} center={center}>
      <Text>
        Monday, Tuesday: 2pm - 8pm
        <br />
        Wednesday - Friday: 2pm - 10pm
        <br />
        Saturday: 12pm - 10pm
        <br />
        Sunday: 12pm - 8pm
      </Text>
      <Text>
        <a href={Routes.PRIVATE_EVENTS_PAGE.path}>
          Reservations and private events available upon request
        </a>
      </Text>
    </InfoCard>
  );
};

export default HoursInfoCard;
