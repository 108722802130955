// Types
import { EventType } from "../../types";
import { BodsCalendarEventType } from "../../design_system/components/calendar";

// Components
import { BodsCalendar } from "../../design_system/components";
// import CalendarEventCard from "./CalendarEventCard";

type Props = {
  events: EventType[];
  eventComponent: any;
};

const EventCalendar = ({ events = [], eventComponent }: Props) => {
  const formattedEvents: Array<BodsCalendarEventType> = events.map((event) => {
    const date = new Date(event.date);
    const formattedDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60000
    );
    return {
      ...event,
      id: event.sys.id,
      start: formattedDate,
      end: formattedDate,
      allDay: true,
      title: event.title,
    };
  });

  const EventComponent = eventComponent;

  return (
    <BodsCalendar events={formattedEvents} eventComponent={EventComponent} />
  );
};

export default EventCalendar;
