import { Children } from "react";

import { BodsButton, BodsIcon } from "../";

import "./BodsModalHeader.scss";

export interface Props {
  children?: React.ReactNode;
  onToggle?(): void;
}

const classNamePrefix = "BodsModalHeader";

const BodsModalHeader: React.FC<Props> = ({
  children,
  onToggle,
}: Props) => {
  return (
    <div className={classNamePrefix}>
      <div>
        {Children.map(children, (child) => {
          // if (child && isElementOfType(child, [WpTabs])) {
          //   return <div className={`${classNamePrefix}__tabs`}>{React.cloneElement(child)}</div>;
          // }
          return <div className={`${classNamePrefix}__title`}>{child}</div>;
        })}
      </div>
      {onToggle && (
        <div className={`${classNamePrefix}__btn-close`}>
          <BodsButton onClick={onToggle} color="tertiary">
            <BodsIcon name="close" />
          </BodsButton>
        </div>
      )}
    </div>
  );
};

export default BodsModalHeader;
