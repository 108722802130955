const prefix = "outline";
const iconName = "clock";
const viewBox = "0 0 22 22";

const svg = (color) => (
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-1.000000, -1.000000)">
      <g>
        <g>
          <path
            d="M1.25,12 C1.25,6.07000017 6.07000017,1.25 12,1.25 C17.9299998,1.25 22.75,6.07000017 22.75,12 C22.75,17.9299998 17.9299998,22.75 12,22.75 C6.07000017,22.75 1.25,17.9299998 1.25,12 Z M2.75,12 C2.75,17.0999999 6.9000001,21.25 12,21.25 C17.0999999,21.25 21.25,17.0999999 21.25,12 C21.25,6.9000001 17.0999999,2.75 12,2.75 C6.9000001,2.75 2.75,6.9000001 2.75,12 Z"
            fill={color}
          ></path>
          <path
            d="M15.329648,15.8200092 L12.2296476,13.9700098 C11.4596477,13.5100098 10.8896484,12.5000092 10.8896484,11.6100092 L10.8896484,7.51000977 C10.8896484,7.10000977 11.2296484,6.76000977 11.6396484,6.76000977 C12.0496484,6.76000977 12.3896484,7.10000977 12.3896484,7.51000977 L12.3896484,11.6100092 C12.3896484,11.9700092 12.6896481,12.5000098 12.9996481,12.6800098 L16.0996485,14.5300093 C16.4596485,14.7400093 16.5696487,15.2000099 16.3596487,15.56001 C16.2096487,15.80001 15.9596481,15.9300098 15.7096481,15.9300098 C15.5796481,15.9300098 15.449648,15.9000092 15.329648,15.8200092 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" opacity="0"></path>
        </g>
      </g>
    </g>
  </g>
);

export const clock = {
  prefix,
  iconName, // Any name you like
  svg, // svg path data
  viewBox,
};
