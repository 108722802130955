import moment, { Moment, isMoment } from "moment";

export function getCurrentMoment(): Moment {
  return moment();
}

export function convertUnixToMoment(date: number) {
  return moment.unix(date);
}

export function convertToMoment(date: string | Date) {
  return moment(date);
}

export function convertMomentToUnix(date: Moment) {
  return moment(date).unix();
}

export function isInThePast(date: Moment) {
  return moment().unix() > convertMomentToUnix(date);
}

// https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
export function formatDate(
  date: Moment | string | number | Date,
  format: string
) {
  if (isMoment(date)) return date.format(format);
  if (typeof date === "number") return convertUnixToMoment(date).format(format);
  else return convertToMoment(date).utc().format(format);
}
