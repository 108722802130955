const prefix = "outline";
const iconName = "plus";
const viewBox = "0 0 14 14";

const svg = (color) => (
  <g
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g transform="translate(-5.000000, -5.000000)">
      <g>
        <g>
          <path
            d="M6,12.75 C5.59,12.75 5.25,12.41 5.25,12 C5.25,11.59 5.59,11.25 6,11.25 L18,11.25 C18.41,11.25 18.75,11.59 18.75,12 C18.75,12.41 18.41,12.75 18,12.75 L6,12.75 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M11.25,18 L11.25,6 C11.25,5.59 11.59,5.25 12,5.25 C12.41,5.25 12.75,5.59 12.75,6 L12.75,18 C12.75,18.41 12.41,18.75 12,18.75 C11.59,18.75 11.25,18.41 11.25,18 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M24,0 L24,24 L0,24 L0,0 L24,0 Z"
            opacity="0"
          ></path>
        </g>
      </g>
    </g>
  </g>
);

export const plus = {
  prefix,
  iconName, // Any name you like
  svg, // svg path data
  viewBox,
};
