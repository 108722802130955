// Hooks
import { useAlertBanner } from "../../hooks/use-alert-banner";


// Components
import {
  Container,
  Row,
  Column,
  BodsAlert,
} from "../../design_system/components";

// Styles
import "./PageAlert.scss";

const PageAlert = () => {
  const { hasAlert, alert } = useAlertBanner();
  const baseClassName = "PageAlert";

  if (!hasAlert) return null;
  return (
    <Container className={baseClassName}>
      <Row>
        <Column>
          <BodsAlert
            text={alert.text}
            linkText={alert.linkText}
            link={alert.link}
          />
        </Column>
      </Row>
    </Container>
  );
};

export default PageAlert;
