// Types
import { EventType } from "../../types";
import { RouteType } from "../../constants/Routes.constants";

// Componets
import { Row, Column } from "../../design_system/components";
import EventCard from "../EventCard/EventCard";

// Styles
import "./EventCardList.scss";

type Props = {
  events: EventType[];
  route?: RouteType;
  showLink?: boolean;
  showShortDescription?: boolean;
};

const EventCardList = ({
  events,
  route,
  showLink,
  showShortDescription = false,
}: Props) => {
  const baseClassName = "EventCardList";
  return (
    <Row>
      {events.map((event) => (
        <Column sm="12" md="6" lg="4" xl="4">
          <EventCard
            event={event}
            route={route}
            showLink={showLink}
            className={`${baseClassName}__item`}
            showShortDescription={showShortDescription}
          />
        </Column>
      ))}
    </Row>
  );
};

export default EventCardList;
