// Hooks
import { useTaproomFaq } from "../../hooks/use-taproom-faq";

// Components
import { Container, Row, Column, Text } from "../../design_system/components";
import AddressInfoCard from "../AddressInfoCard/AddressInfoCard";
import HoursInfoCard from "../HoursInfoCard/HoursInfoCard";
import FaqList from "../FaqList/FaqList";

// Styles
import "./TaproomPage.scss";

const TaproomPage = () => {
  const { isLoading, data } = useTaproomFaq();

  const baseClassName = "TaproomPage";

  return (
    <div className={baseClassName}>
      <Container>
        <Row center>
          <Column md="12" lg="8" xl="8">
            <div className={`${baseClassName}__map-responsive`}>
              <iframe
                title="Taproom Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.9056562054916!2d-122.25515568414215!3d37.51014323511244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fa20f77040e01%3A0xfeeb90d7a4b0e1c2!2sBlue+Oak+Brewing+Company!5e0!3m2!1sen!2sus!4v1516529670728"
                width="1100"
                height="300"
                frameBorder="0" //
                style={{ border: "0" }}
                allowFullScreen
              ></iframe>
            </div>
          </Column>
          <Column md="12" lg="4" xl="4">
            <div className={`${baseClassName}__address`}>
              <AddressInfoCard title="Visit Us" />
            </div>
            <HoursInfoCard />
          </Column>
        </Row>
      </Container>
      <Container paddingY={3} className={`${baseClassName}__faqs`}>
        <Row>
          <Column size={12}>
            <Text tag="h1" className={`${baseClassName}__faqs__title`}>
              FAQ
            </Text>
          </Column>
        </Row>
        <Row>
          <Column sm="hidden" md="2" lg="2" xl="2" />
          {isLoading ? (
            <>Loading...</>
          ) : (
            <Column sm="12" md="8" lg="8" xl="8">
              <div className={`${baseClassName}__faqs__list`}>
                <FaqList faqs={data} />
              </div>
            </Column>
          )}
          <Column sm="hidden" md="2" lg="2" xl="2" />
        </Row>
      </Container>
    </div>
  );
};

export default TaproomPage;

// https://blog.logrocket.com/integrating-google-maps-react/
