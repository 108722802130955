const prefix = "outline";
const iconName = "calendar";
const viewBox = "0 0 20 22";

const svg = (color) => (
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-2.000000, -1.000000)">
      <g>
        <g>
          <path
            d="M7.25,5 L7.25,2 C7.25,1.59 7.59,1.25 8,1.25 C8.41,1.25 8.75,1.59 8.75,2 L8.75,5 C8.75,5.41 8.41,5.75 8,5.75 C7.59,5.75 7.25,5.41 7.25,5 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M15.25,5 L15.25,2 C15.25,1.59 15.59,1.25 16,1.25 C16.41,1.25 16.75,1.59 16.75,2 L16.75,5 C16.75,5.41 16.41,5.75 16,5.75 C15.59,5.75 15.25,5.41 15.25,5 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M8.11999893,14.4200809 C7.98999894,14.3700809 7.89000092,14.3000589 7.79000092,14.2100589 C7.61000091,14.0200589 7.5,13.7700674 7.5,13.5000674 C7.5,13.3700674 7.53000183,13.2400625 7.58000183,13.1200625 C7.63000183,13.0000625 7.70000091,12.890076 7.79000092,12.790076 C7.89000092,12.700076 7.98999894,12.630054 8.11999893,12.580054 C8.47999895,12.430054 8.92999908,12.510076 9.20999908,12.790076 C9.38999909,12.980076 9.5,13.2400674 9.5,13.5000674 C9.5,13.5600674 9.48999954,13.6300797 9.47999954,13.7000797 C9.46999954,13.7600797 9.44999817,13.8200724 9.41999817,13.8800724 C9.39999817,13.9400724 9.37000183,14.000065 9.33000183,14.060065 C9.30000183,14.110065 9.24999908,14.1600589 9.20999908,14.2100589 C9.01999909,14.3900589 8.75999999,14.5000675 8.5,14.5000675 C8.37,14.5000675 8.23999893,14.4700809 8.11999893,14.4200809 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M11.6199989,14.4198866 C11.4899989,14.3698866 11.3900009,14.2998646 11.2900009,14.2098646 C11.1100009,14.0198646 11,13.7698732 11,13.4998732 C11,13.3698732 11.0300018,13.2398683 11.0800018,13.1198683 C11.1300018,12.9998683 11.2000009,12.8898817 11.2900009,12.7898817 C11.3900009,12.6998817 11.4899989,12.6298598 11.6199989,12.5798598 C11.9799989,12.4198598 12.4299991,12.5098817 12.7099991,12.7898817 C12.8899991,12.9798817 13,13.2398732 13,13.4998732 C13,13.5598732 12.9899995,13.6298854 12.9799995,13.6998854 C12.9699995,13.7598854 12.9499982,13.819878 12.9199982,13.879878 C12.8999982,13.939878 12.8700018,13.9998707 12.8300018,14.0598707 C12.8000018,14.1098707 12.7499991,14.1598646 12.7099991,14.2098646 C12.5199991,14.3898646 12.26,14.4998732 12,14.4998732 C11.87,14.4998732 11.7399989,14.4698866 11.6199989,14.4198866 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M15.1199989,14.4198866 C14.9899989,14.3698866 14.8900009,14.2998646 14.7900009,14.2098646 C14.7500009,14.1598646 14.7099982,14.1098707 14.6699982,14.0598707 C14.6299982,13.9998707 14.6000018,13.939878 14.5800018,13.879878 C14.5500018,13.819878 14.5300005,13.7598854 14.5200005,13.6998854 C14.5100005,13.6298854 14.5,13.5598732 14.5,13.4998732 C14.5,13.2398732 14.6100009,12.9798817 14.7900009,12.7898817 C14.8900009,12.6998817 14.9899989,12.6298598 15.1199989,12.5798598 C15.4899989,12.4198598 15.9299991,12.5098817 16.2099991,12.7898817 C16.3899991,12.9798817 16.5,13.2398732 16.5,13.4998732 C16.5,13.5598732 16.4899995,13.6298854 16.4799995,13.6998854 C16.4699995,13.7598854 16.4499982,13.819878 16.4199982,13.879878 C16.3999982,13.939878 16.3700018,13.9998707 16.3300018,14.0598707 C16.3000018,14.1098707 16.2499991,14.1598646 16.2099991,14.2098646 C16.0199991,14.3898646 15.76,14.4998732 15.5,14.4998732 C15.37,14.4998732 15.2399989,14.4698866 15.1199989,14.4198866 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M8.11999893,17.9199561 C8,17.8699561 7.89000092,17.7999341 7.79000092,17.7099341 C7.61000091,17.5199341 7.5,17.2599427 7.5,16.9999427 C7.5,16.8699427 7.53000183,16.7399378 7.58000183,16.6199378 C7.63000183,16.4899378 7.70000091,16.3799512 7.79000092,16.2899512 C8.16000092,15.9199512 8.83999908,15.9199512 9.20999908,16.2899512 C9.38999909,16.4799512 9.5,16.7399427 9.5,16.9999427 C9.5,17.2599427 9.38999909,17.5199341 9.20999908,17.7099341 C9.01999909,17.8899341 8.75999999,17.9999427 8.5,17.9999427 C8.37,17.9999427 8.23999893,17.9699561 8.11999893,17.9199561 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M11.2900009,17.7099341 C11.1100009,17.5199341 11,17.2599427 11,16.9999427 C11,16.8699427 11.0300018,16.7399378 11.0800018,16.6199378 C11.1300018,16.4899378 11.2000009,16.3799512 11.2900009,16.2899512 C11.6600009,15.9199512 12.3399991,15.9199512 12.7099991,16.2899512 C12.7999991,16.3799512 12.8699982,16.4899378 12.9199982,16.6199378 C12.9699982,16.7399378 13,16.8699427 13,16.9999427 C13,17.2599427 12.8899991,17.5199341 12.7099991,17.7099341 C12.5199991,17.8899341 12.26,17.9999427 12,17.9999427 C11.74,17.9999427 11.4800009,17.8899341 11.2900009,17.7099341 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M14.7900009,17.7099046 C14.7000009,17.6199045 14.6300018,17.509918 14.5800018,17.379918 C14.5300018,17.259918 14.5,17.1299131 14.5,16.9999131 C14.5,16.8699131 14.5300018,16.7399082 14.5800018,16.6199082 C14.6300018,16.4899082 14.7000009,16.3799216 14.7900009,16.2899216 C15.0200009,16.0599216 15.3699986,15.9499021 15.6899986,16.0199021 C15.7599986,16.0299021 15.8200011,16.0498997 15.8800011,16.0798997 C15.9400011,16.0998997 16,16.1299265 16.0600014,16.1699265 C16.1100014,16.1999265 16.1599991,16.2499216 16.2099991,16.2899216 C16.3899991,16.4799216 16.5,16.7399131 16.5,16.9999131 C16.5,17.2599131 16.3899991,17.5199046 16.2099991,17.7099046 C16.0199991,17.8899046 15.76,17.999913 15.5,17.999913 C15.24,17.999913 14.9800009,17.8899046 14.7900009,17.7099046 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M3.5,9.83984375 C3.09,9.83984375 2.75,9.49984375 2.75,9.08984375 C2.75,8.67984375 3.09,8.33984375 3.5,8.33984375 L20.5,8.33984375 C20.91,8.33984375 21.25,8.67984375 21.25,9.08984375 C21.25,9.49984375 20.91,9.83984375 20.5,9.83984375 L3.5,9.83984375 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M8,22.75 C4.3499999,22.75 2.25,20.6500001 2.25,17 L2.25,8.5 C2.25,4.8499999 4.3499999,2.75 8,2.75 L16,2.75 C19.6500001,2.75 21.75,4.8499999 21.75,8.5 L21.75,17 C21.75,20.6500001 19.6500001,22.75 16,22.75 L8,22.75 Z M3.75,8.5 L3.75,17 C3.75,19.8599999 5.1400001,21.25 8,21.25 L16,21.25 C18.8599999,21.25 20.25,19.8599999 20.25,17 L20.25,8.5 C20.25,5.6400001 18.8599999,4.25 16,4.25 L8,4.25 C5.1400001,4.25 3.75,5.6400001 3.75,8.5 Z"
            fill={color}
          ></path>
          <path
            d="M24,0 L24,24 L0,24 L0,0 L24,0 Z"
            opacity="0"
          ></path>
        </g>
      </g>
    </g>
  </g>
);

export const calendar = {
  prefix,
  iconName, // Any name you like
  svg, // svg path data
  viewBox,
};

