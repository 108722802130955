import { gql } from "graphql-request";

// Utils
import { getCurrentMoment } from "../utils/DateTime.utils";

export const foodTruckCalendarCollection = gql`
  {
    foodTruckCalendarCollection(
      where: {date_gt: "${getCurrentMoment()
        .subtract(1, "days")
        .startOf("day")
        .toISOString()}"},
      order: [date_ASC]
      ) {
      items {
        date
        time
        foodTruck {
          name
          websiteLink
          shortDescription
          description {
            json
          }
          image {
            url
          }
        }
        sys {
          id
        }
      }
    }
  }
`;
