// @ts-nocheck
import { useQuery } from "@tanstack/react-query";

// Types
import { PageType } from "../types/Page.type";

// GQL
import { gqlClient } from "../gql/gqlClient";
import { pagesCollection } from "../gql/pagesCollection.query";

type Props = {
  path: string;
};

export const usePages = ({ path }: Props) => {
  const { isLoading, data }: { isLoading: boolean; data: PageType[] } =
    useQuery(["pages"], () =>
      gqlClient
        .request(pagesCollection)
        .then((data) => data.pagesCollection.items)
    );

  const page = data?.find((page) => page.path === path);
  const faqs = page?.faqsCollection?.items;
  const photos = page?.photoGalleryCollection?.items;
  const showName = page?.showTitle;
  const name = page?.name;

  return {
    isLoading,
    page,
    faqs,
    photos,
    name,
    showName,
  };
};
