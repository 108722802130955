// @ts-nocheck
import { Children, cloneElement } from "react";

// Utils
// import { isElementOfType } from "../../utils/utils";

// Components
// import { BodsInput, BodsTextarea, BodsButton } from "../";

// Styles
import "./BodsForm.scss";

type Props = {
  id: string;
  name?: string;
  netlifyHoneypotId?: string;
  isNetlifyForm?: boolean;
  children?: React.ReactNode;
};

const BodsForm = ({
  id,
  name,
  netlifyHoneypotId,
  isNetlifyForm,
  children,
  ...rest
}: Props) => {
  const baseClassName = "BodsForm";
  return (
    <form
      id={id}
      name={name ? name : id}
      className={baseClassName}
      netlify-honeypot={netlifyHoneypotId}
      // @ts-ignore
      netlify={isNetlifyForm}
      {...rest}
    >
      {Children.map(children, (child) => (
        <div className={`${baseClassName}__form-group`}>
          {cloneElement(child)}
        </div>
      ))}

      {/* {Children.map(children, (child: React.ReactElement<any>) => { */}
      {/* {Children.map(children, (child) => {
        if (
          child &&
          isElementOfType(child, [BodsInput, BodsTextarea, BodsButton])
        ) {
          return (
            <div className={`${baseClassName}__form-group`}>
              {cloneElement(child)}
            </div>
          );
        } else return cloneElement(child);
      })} */}
    </form>
  );
};

export default BodsForm;
