// Types
import { PhotoGalleryPhotoType } from "./types";

// Components
import PhotoGalleryItem from "./PhotoGalleryItem";

// Styles
import "./PhotoGallery.scss";

type Props = {
  galleryId: string;
  photos: PhotoGalleryPhotoType[];
};

const PhotoGallery = ({ galleryId, photos }: Props) => {
  const baseClassName = "PhotoGallery";
  if (!photos) return null;
  return (
    <div className={baseClassName}>
      {photos.map((photo, index) => (
        <PhotoGalleryItem key={`${galleryId}-${index}`} photo={photo} />
      ))}
    </div>
  );
};

export default PhotoGallery;
