const prefix = "outline";
const iconName = "arrow-right";
const viewBox = "0 0 20 14";

const svg = (color) => (
  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g
      id="vuesax/outline/arrow-right"
      transform="translate(-2.000000, -5.000000)"
    >
      <g id="arrow-right">
        <g>
          <path
            d="M13.8991406,18.6001177 C13.6091406,18.3101177 13.6091406,17.8301182 13.8991406,17.5401182 L19.4391418,12.0001173 L13.8991406,6.4601171 C13.6091406,6.17011711 13.6091406,5.69011718 13.8991406,5.40011719 C14.1891406,5.1101172 14.669141,5.1101172 14.959141,5.40011719 L21.0291419,11.4701176 C21.3191419,11.7601176 21.3191419,12.240117 21.0291419,12.530117 L14.959141,18.6001177 C14.809141,18.7501177 14.6191413,18.820117 14.4291413,18.820117 C14.2391413,18.820117 14.0491406,18.7501177 13.8991406,18.6001177 Z"
            id="Vector"
            fill="#292D32"
            fillRule="nonzero"
          ></path>
          <path
            d="M3.5,12.75 C3.09,12.75 2.75,12.41 2.75,12 C2.75,11.59 3.09,11.25 3.5,11.25 L20.3299999,11.25 C20.7399999,11.25 21.0799999,11.59 21.0799999,12 C21.0799999,12.41 20.7399999,12.75 20.3299999,12.75 L3.5,12.75 Z"
            id="Vector"
            fill="#292D32"
            fillRule="nonzero"
          ></path>
          <path
            d="M24,0 L24,24 L0,24 L0,0 L24,0 Z"
            id="Vector"
            opacity="0"
          ></path>
        </g>
      </g>
    </g>
  </g>
);

export const arrowRight = {
  prefix,
  iconName, // Any name you like
  svg, // svg path data
  viewBox,
};
