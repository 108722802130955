import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";

// Styles
import "./ContentfulRichTextRenderer.scss";

type Props = {
  data: {
    json: Document;
  };
};

const ContentfulRichTextRenderer = ({ data }: Props) => {
  return (
    <div className="ContentfulRichTextRenderer">
      {documentToReactComponents(data.json)}
    </div>
  );
};

export default ContentfulRichTextRenderer;
