// @ts-nocheck
import { useParams } from "react-router-dom";

// Images
// import HeroImage from "../../images/beer-banner.png";
import HeroImage from "../../images/brewery_7.png";

// Hooks
import { useJobs } from "../../hooks/use-jobs";

// components
import {
  Container,
  Row,
  Column,
  Text,
  BodsEmptyState,
} from "../../design_system/components";
import BodsImageCard from "../../design_system/components/image-card/BodsImageCard";
import JobsList from "../JobsList/JobsList";
import JobDetails from "../JobDetails/JobDetails";

// Styles
import "./JobsPage.scss";

const JobsPage = () => {
  const { id: jobId } = useParams();
  const { isLoading, data } = useJobs();

  const selectedJob = data?.find((job) => job.sys.id === jobId);

  const showJobDetails = jobId && Boolean(selectedJob);
  const showEmpty = !isLoading && data?.length === 0;

  const baseClassName = "JobsPage";

  return (
    <div className={baseClassName}>
      <Container>
        <Row>
          <Column size={12}>
            <BodsImageCard image={HeroImage}>
              <div style={{ padding: "150px 0" }}></div>
            </BodsImageCard>
          </Column>
        </Row>
      </Container>
      {isLoading ? (
        <>Loading...</>
      ) : (
        <>
          {showJobDetails ? (
            <JobDetails job={selectedJob} />
          ) : (
            <Container paddingY={3} className={`${baseClassName}__job-list`}>
              <Row>
                <Column md="hidden" lg="2" xl="2" />
                <Column md="12" lg="8" xl="8">
                  <Text>
                    We're looking for taptenders, barbacks and beer production
                    staff who are outgoing, enthusiastic about beer, and excited
                    to shape the customer experience in our taproom showcasing
                    our product!
                  </Text>
                  <Text>
                    To apply for a role below, send your resume to&nbsp;
                    <a href="mailtoalex@blueoakbrewing.com">
                      alex@blueoakbrewing.com
                    </a>
                    .
                  </Text>
                  <Row>
                    <Column size={12}>
                      <Text
                        tag="h3"
                        className={`${baseClassName}__faqs__title`}
                      >
                        Open Roles
                      </Text>
                    </Column>
                  </Row>
                  <Row>
                    <Column size={6}>
                      {showEmpty ? (
                        <BodsEmptyState
                          title="No open positions"
                          text="We currently have no open positions. Be sure to check back soon for any opportunities."
                        />
                      ) : (
                        <JobsList jobs={data} />
                      )}
                    </Column>
                  </Row>
                </Column>
                <Column md="hidden" lg="2" xl="2" />
              </Row>
            </Container>
          )}
        </>
      )}
    </div>
  );
};

export default JobsPage;
