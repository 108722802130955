import classnames from "classnames";

// Components
import { InfoCard, Text } from "../../design_system/components";

type Props = {
  title?: string;
  center?: boolean;
  showTitle?: boolean;
  className?: string;
};

const AddressInfoCard = ({
  title = "Location",
  center = false,
  showTitle = true,
  className,
}: Props) => {
  const baseClassName = "AddressInfoCard";
  const classes = classnames(baseClassName, className);
  return (
    <InfoCard title={title} center={center} showTitle={showTitle}>
      <Text>
        <a
          href="https://goo.gl/maps/mkovtJTSosB2"
          target="_blank"
          rel="noopener noreferrer"
          className={classes}
        >
          815 Cherry Lane
          <br />
          San Carlos, CA 94070
        </a>
      </Text>
    </InfoCard>
  );
};

export default AddressInfoCard;
