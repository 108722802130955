import classnames from "classnames";

// Hooks
import { useResponsiveScreenWidth } from "../../hooks/use-responsive-screen-width";

// Styles
import "./Column.scss";

type NumberAttr =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12";

type ColSize = "hidden" | "auto" | NumberAttr;

export interface ColProps {
  xs?: ColSize;
  sm?: ColSize;
  md?: ColSize;
  lg?: ColSize;
  xl?: ColSize;
  children?: React.ReactNode;
  size?: NumberAttr;
  className?: string;
}

const Column = ({
  children,
  size,
  xs,
  sm,
  md,
  lg,
  xl,
  className = "",
}: ColProps) => {
  const { isXtraSmall, isSmall, isMedium, isLarge, isXtraLarge } =
    useResponsiveScreenWidth();

  const getCurrentWidthSpan = () => {
    if (isXtraLarge && xl) return xl;
    if (isLarge && (lg || xl)) return lg || xl;
    if (isMedium && (md || lg || xl)) return md || lg || xl;
    if (isSmall && (sm || md || lg || xl)) return sm || md || lg || xl;
    if (isXtraSmall && (xs || sm || md || lg || xl)) {
      return xs || sm || md || lg || xl;
    }
  };

  const span = getCurrentWidthSpan();
  if (span === "hidden") return null;

  const baseClassName = "Column";
  const classes = classnames(
    baseClassName,
    `${baseClassName}--${span || size}`,
    className
  );

  return <div className={classes}>{children}</div>;
};

export default Column;
