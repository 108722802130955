// Components
import Header from "../Header/Header";
import PageTitle from "../PageTitle/PageTitle";
import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";
import PageAlert from "../PageAlert/PageAlert";

// Styles
import "./PageLayout.scss";

type Props = {
  children: React.ReactNode;
  showBanner?: boolean;
  showNavigation?: boolean;
};

const PageLayout = ({
  children,
  showBanner = true,
  showNavigation = true,
}: Props) => {
  const baseClassName = "PageLayout";
  return (
    <>
      {showBanner && <Banner />}
      <div className={baseClassName}>
        <Header showNavigation={showNavigation} />
        <PageAlert />
        <div className={`${baseClassName}__content`}>
          <PageTitle />
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PageLayout;
