import { Icons, IconNames } from "./library";
import classnames from "classnames";

// Styles
import "./BodsIcon.scss";

export type Size = "16" | "20" | "24" | "32" | "40";

interface IconProperties {
  className?: string;
  title?: string;
  style?: any;
  role?: string;
  size?: Size;
  name: IconNames;
  color?: string;
  container?: "none" | "square" | "circle";
}

const BodsIcon: React.FC<IconProperties> = ({
  title,
  size = "20",
  role = "img",
  name,
  color = "black",
  container = "none",
  ...props
}) => {
  const path = Icons[name].svg(color);
  const viewBox = Icons[name].viewBox;

  const showInContainer = container === "square" || container === "circle";

  const baseClassName = "BodsIcon";
  const classes = classnames(
    baseClassName,
    showInContainer && `${baseClassName}--${container}`
  );

  const icon = (
    <svg width={size} height={size} viewBox={viewBox} {...props}>
      {title && <title>{title}</title>}
      {path}
    </svg>
  );

  return (
    <>{showInContainer ? <div className={classes}>{icon}</div> : <>{icon}</>}</>
  );
};

// BodsIcon.defaultProps = {
//   size: "20",
//   role: "img",
//   color: "black",
//   container:
// };

export default BodsIcon;
