// Components
import { Container, Row, Column } from "../../design_system/components";

const NotFoundPage = () => {
  return (
    <div>
      <Container>
        <Row>
          <Column>Page Not Found</Column>
        </Row>
      </Container>
    </div>
  );
};

export default NotFoundPage;
