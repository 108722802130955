const prefix = "outline";
const iconName = "danger";
const viewBox = "0 0 22 22";

const svg = (color) => (
  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="vuesax/outline/danger" transform="translate(-1.000000, -1.000000)">
      <g id="danger">
        <g>
          <path
            d="M11.25,14 L11.25,9 C11.25,8.59 11.59,8.25 12,8.25 C12.41,8.25 12.75,8.59 12.75,9 L12.75,14 C12.75,14.41 12.41,14.75 12,14.75 C11.59,14.75 11.25,14.41 11.25,14 Z"
            id="Vector"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M11.8000002,17.9800577 C11.7400002,17.9700577 11.6799999,17.9500582 11.6199999,17.9200583 C11.5599999,17.9000583 11.5,17.8700581 11.4399996,17.8300581 C11.3899996,17.7900581 11.34,17.7500573 11.29,17.7100573 C11.11,17.5200573 11,17.2600582 11,17.0000582 C11,16.7400582 11.11,16.4800591 11.29,16.2900591 C11.34,16.2500591 11.3899996,16.2100582 11.4399996,16.1700582 C11.5,16.1300582 11.5599999,16.1000581 11.6199999,16.0800581 C11.6799999,16.0500581 11.7400002,16.0300586 11.8000002,16.0200586 C11.9300002,15.9900586 12.0699996,15.9900586 12.1899996,16.0200586 C12.2599996,16.0300586 12.3200001,16.0500581 12.3800001,16.0800581 C12.4400001,16.1000581 12.5,16.1300582 12.5600004,16.1700582 C12.6100004,16.2100582 12.66,16.2500591 12.71,16.2900591 C12.89,16.4800591 13,16.7400582 13,17.0000582 C13,17.2600582 12.89,17.5200573 12.71,17.7100573 C12.66,17.7500573 12.6100004,17.7900581 12.5600004,17.8300581 C12.5,17.8700581 12.4400001,17.9000583 12.3800001,17.9200583 C12.3200001,17.9500582 12.2599996,17.9700577 12.1899996,17.9800577 C12.1299996,17.9900577 12.06,18.0000582 12,18.0000582 C11.94,18.0000582 11.8700002,17.9900577 11.8000002,17.9800577 Z"
            id="Vector"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M5.94046402,22.1600876 C3.99046397,22.1600876 2.50046417,21.4500878 1.74046418,20.1700878 C0.990464181,18.8900878 1.09046412,17.2400875 2.0404641,15.5300875 L8.10046434,4.63008785 C9.10046434,2.8300879 10.4804645,1.84008789 12.0004644,1.84008789 C13.5204644,1.84008789 14.9004641,2.8300879 15.9004641,4.63008785 L21.9604626,15.5400877 C22.9104626,17.2500877 23.0204637,18.8900881 22.2604637,20.180088 C21.5004637,21.450088 20.010463,22.1600876 18.060463,22.1600876 L5.94046402,22.1600876 Z M9.41046429,5.36008787 L3.3604641,16.2700882 C2.68046409,17.4900882 2.57046416,18.6100878 3.04046416,19.4200878 C3.51046416,20.2300878 4.5504638,20.6700878 5.95046377,20.6700878 L18.0704632,20.6700878 C19.4704632,20.6700878 20.500463,20.2300878 20.980463,19.4200878 C21.460463,18.6100878 21.3404633,17.5000882 20.6604633,16.2700882 L14.5904646,5.36008787 C13.8604646,4.06008792 12.9404644,3.34008789 12.0004644,3.34008789 C11.0604644,3.34008789 10.1404643,4.06008792 9.41046429,5.36008787 Z"
            id="Vector"
            fill={color}
          ></path>
          <path
            d="M24,0 L24,24 L0,24 L0,0 L24,0 Z"
            id="Vector"
            opacity="0"
          ></path>
        </g>
      </g>
    </g>
  </g>
);

export const danger = {
  prefix,
  iconName, // Any name you like
  svg, // svg path data
  viewBox,
};
