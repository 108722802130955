import { useEffect, useContext } from "react";
import {
  // ButtonBack,
  // ButtonNext,
  // DotGroup,
  Slide,
  Slider,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { CarouselContext } from "pure-react-carousel";

// Constants
import { Routes } from "../../constants/Routes.constants";

// Types
import { EventType } from "../../types";

// Hooks
import useWindowDimensions from "../../design_system/hooks/use-window-dimensions";

// Components
// import { BodsIcon } from "../../design_system/components";
import EventCard from "../EventCard/EventCard";

// Styles
import "./CarouselSlider.scss";

type Props = {
  events: EventType[];
  setSlideCount: Function;
  setCurrentSlide: any;
};

const CarouselSlider = ({ events, setSlideCount, setCurrentSlide }: Props) => {
  const { width: screenWidth } = useWindowDimensions();

  const carouselContext = useContext(CarouselContext);

  useEffect(() => {
    const updateCarouselSlide = (slideToBeVisible: number) => {
      const { currentSlide, totalSlides, visibleSlides } =
        carouselContext.state;

      setSlideCount(slideToBeVisible);

      //this is a fix to reset currentSlide when screen resizes
      if (
        (currentSlide >= totalSlides - visibleSlides &&
          totalSlides - visibleSlides > 0) ||
        (currentSlide >= totalSlides - slideToBeVisible &&
          totalSlides - slideToBeVisible > 0)
      ) {
        setCurrentSlide(totalSlides - slideToBeVisible);
      }
    };

    if (screenWidth < 832) {
      updateCarouselSlide(1);
    } else if (screenWidth < 1088) {
      updateCarouselSlide(2);
    }
    //>= 1088
    else {
      updateCarouselSlide(3);
    }
  }, [screenWidth, setSlideCount, setCurrentSlide, carouselContext]);

  const baseClassName = "CarouselSlider";

  return (
    <div className={baseClassName}>
      <Slider>
        {events.map((slide, index) => (
          <Slide
            key={index}
            index={index}
            className={`${baseClassName}__slide`}
          >
            <EventCard
              event={slide}
              route={Routes.EVENT_DETAILS_PAGE}
              showLink={false}
              showShortDescription
            />
          </Slide>
        ))}
      </Slider>
      {/* <div className={`${baseClassName}__controls`}>
        <ButtonBack className="BodsButton BodsButton--tertiary BodsButton--icon">
          <BodsIcon name="chevronLeft" />
        </ButtonBack>
        <DotGroup className="dot-group" />
        <ButtonNext className="BodsButton BodsButton--tertiary BodsButton--icon">
          <BodsIcon name="chevronRight" />
        </ButtonNext>
      </div> */}
    </div>
  );
};

export default CarouselSlider;
