import { Provider, ErrorBoundary } from "@rollbar/react";

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
};

type Props = {
  children: React.ReactNode;
};

const Rollbar = ({ children }: Props) => {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
};

export default Rollbar;
