// Types
import { MenuItemType } from "../../types";

import { Text } from "../../design_system/components";

// Styles
import "./BeerListItem.scss";

type Props = {
  menuItem: MenuItemType;
};

const BeerListItem = ({ menuItem }: Props) => {
  const baseClassName = "BeerListItem";

  return (
    <div className={baseClassName}>
      <Text className={`${baseClassName}__name`} tag="h2">
        {menuItem.name}
      </Text>
      {menuItem.style && menuItem.abv && (
        <div className={`${baseClassName}__meta`}>
          <Text className={`${baseClassName}__meta__style`} tag="h6">
            {menuItem.style}
          </Text>
          <div className={`${baseClassName}__meta__dot`} />
          <Text className={`${baseClassName}__meta__abv`} tag="h6">
            {menuItem.abv}% ABV
          </Text>
        </div>
      )}
      <Text className={`${baseClassName}__description`}>
        {menuItem.description}
      </Text>
    </div>
  );
};

export default BeerListItem;
