import { gql } from "graphql-request";

export const bannerCollection = gql`
  {
    bannerCollection(where: { contentfulMetadata: { tags_exists: false } }) {
      items {
        text
        linkText
        link
        sys {
          id
        }
      }
    }
  }
`;
