import { instagram } from "./iconInstagram";
import { facebook } from "./iconFacbook";
import { untappd } from "./iconUntappd";
import { clock } from "./iconClock";
import { calendar } from "./iconCalendar";
import { menu } from "./iconMenu";
import { close } from "./iconClose";
import { plus } from "./iconPlus";
import { chevronLeft } from "./iconChevronLeft";
import { chevronRight } from "./iconChevronRight";
import { globe } from "./iconGlobe";
import { arrowRight } from "./iconArrowRight";
import { danger } from "./iconDanger";

export const Icons = {
  instagram,
  facebook,
  untappd,
  clock,
  calendar,
  menu,
  close,
  plus,
  chevronLeft,
  chevronRight,
  globe,
  arrowRight,
  danger,
} as const;

export type IconNames = keyof typeof Icons;
