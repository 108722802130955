import classnames from "classnames";

// Components
import { Text } from "../";

// Styles
import "./InfoCard.scss";

type Props = {
  title: string;
  children: React.ReactNode;
  center?: boolean;
  showTitle?: boolean;
};

const InfoCard = ({
  title,
  children,
  center = false,
  showTitle = true,
}: Props) => {
  const baseClassName = "InfoCard";
  const classes = classnames(
    baseClassName,
    center && `${baseClassName}--center`
  );

  return (
    <div className={classes}>
      {showTitle && <Text tag="h3">{title}</Text>}
      {children}
    </div>
  );
};

export default InfoCard;
