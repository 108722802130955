import { gql } from "graphql-request";

export const bannerAlertCollection = gql`
  {
    bannerCollection(
      where: {
        contentfulMetadata: {
          tags_exists: true
          tags: { id_contains_some: ["alert"] }
        }
      }
    ) {
      items {
        text
        linkText
        link
        sys {
          id
        }
      }
    }
  }
`;
