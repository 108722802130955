import { useState, useEffect } from "react";

// Images
import HeroImage from "../../images/beer-banner.png";

// Types
import { MenuItemType } from "../../types";

// Actions
import { getMenu } from ".././../actions/menu.actions";

// Components
import { Container, Row, Column, Text } from "../../design_system/components";
import BodsImageCard from "../../design_system/components/image-card/BodsImageCard";
import BeerList from "../BeerList/BeerList";

// Styles
import "./BeerPage.scss";

const BeerPage = () => {
  const [menu, setMenu] = useState<MenuItemType[]>();

  useEffect(() => {
    getMenu().then((response: MenuItemType[]) => {
      setMenu(response.filter((item) => item.onTap));
    });
  }, []);

  const baseClassName = "BeerPage";

  return (
    <div className={baseClassName}>
      <Container>
        <Row>
          <Column size={12}>
            <BodsImageCard image={HeroImage}>
              <div className={`${baseClassName}__image-container`}></div>
            </BodsImageCard>
          </Column>
        </Row>
      </Container>
      <Container>
        <Row>
          <Column size={12}>
            <Text tag="h1" className={`${baseClassName}__title`}>
              On Tap
            </Text>
          </Column>
          <Column size={12}>
            {!menu ? <>loading</> : <BeerList menu={menu} />}
          </Column>
        </Row>
      </Container>
    </div>
  );
};

export default BeerPage;
