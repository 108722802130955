import { gql } from "graphql-request";

export const jobsCollection = gql`
  {
    jobsCollection {
      items {
        title
        order
        jobDetails {
          json
        }
        sys {
          id
        }
      }
    }
  }
`;
