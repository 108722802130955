// Images
import HeroImage from "../../images/beer-banner.png";

// Components
import {
  Container,
  Row,
  Column,
  BodsEmptyState,
  BodsButton,
} from "../../design_system/components";
import BodsImageCard from "../../design_system/components/image-card/BodsImageCard";

const StorePage = () => {
  const handleButtonClick = () => {
    const element = document.createElement("a");
    element.href = "https://store.blueoakbrewing.com/";
    element.target = "_blank";
    element.rel = "noopener noreferrer";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <>
      <Container>
        <Row>
          <Column size={12}>
            <BodsImageCard image={HeroImage}>
              <div style={{ padding: "150px 0" }}></div>
            </BodsImageCard>
          </Column>
        </Row>
      </Container>
      <Container paddingY={4}>
        <Row>
          <Column>
            <BodsEmptyState
              title={
                <>
                  To see our current on tap offerings and order online, visit
                  our&nbsp;
                  <a
                    href="https://store.blueoakbrewing.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    online store
                  </a>
                  .
                </>
              }
              showBorder={false}
              button={
                <BodsButton onClick={handleButtonClick}>
                  Order To-Go Beer
                </BodsButton>
              }
            />
          </Column>
        </Row>
      </Container>
    </>
  );
};

export default StorePage;
