import {
  CarouselProvider,
  Slider,
  Slide,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

// Components
import BodsImageCard from "../../design_system/components/image-card/BodsImageCard";

// Styles
import "./HeroCarousel.scss";

type Props = {
  images: string[];
};

const HeroCarousel = ({ images }: Props) => {
  const baseClassName = "HeroCarousel";
  return (
    <CarouselProvider
      totalSlides={images.length}
      step={1}
      naturalSlideWidth={100}
      naturalSlideHeight={125}
      isIntrinsicHeight={true}
      isPlaying={true}
      interval={6000}
      infinite={false}
      className={baseClassName}
    >
      <Slider>
        {images.map((image, index) => (
          <Slide
            key={index}
            index={index}
            className={`${baseClassName}__slide`}
          >
            <BodsImageCard image={image}>
              <div className={`${baseClassName}__slide__image`}></div>
            </BodsImageCard>
          </Slide>
        ))}
      </Slider>
    </CarouselProvider>
  );
};

export default HeroCarousel;
