import { useState } from "react";

import { CarouselProvider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

// Types
import { EventType } from "../../types";
import { RouteType } from "../../constants/Routes.constants";

// Componets
import CarouselSlider from "./CarouselSlider";

type Props = {
  events: EventType[];
  route?: RouteType;
};

const EventCardCarousel = ({ events, route }: Props) => {
  const [slideCount, setSlideCount] = useState(3);
  const [currentSlide, setCurrentSlide] = useState(0);

  if (events.length === 0) return null;
  return (
    <CarouselProvider
      visibleSlides={slideCount}
      totalSlides={events.length}
      step={1}
      currentSlide={currentSlide}
      naturalSlideWidth={100}
      naturalSlideHeight={125}
      isIntrinsicHeight={true}
    >
      <CarouselSlider
        setSlideCount={setSlideCount}
        setCurrentSlide={setCurrentSlide}
        events={events}
      />
    </CarouselProvider>
  );
};

export default EventCardCarousel;
