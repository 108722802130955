// Types
import { IconNames } from "../../design_system/components/icon/library";

// Components
import { BodsIcon } from "../../design_system/components";

// Styles
import "./IconAttribute.scss";

type Props = {
  icon: IconNames;
  value: string;
  children?: React.ReactNode;
};

const IconAttribute = ({ icon, value, children }: Props) => {
  const baseClassName = "IconAttribute";

  return (
    <div className={baseClassName}>
      <BodsIcon name={icon} container="square" size="16" />
      <span className={`${baseClassName}__value`}>{children || value}</span>
    </div>
  );
};

export default IconAttribute;
