import classnames from "classnames";

// Components
import { Text } from "../";

// Styles
import "./BodsEmptyState.scss";

type Props = {
  title?: string | React.ReactNode;
  text?: string;
  button?: React.ReactNode;
  showBorder?: boolean;
};

const BodsEmptyState = ({ title, text, button, showBorder = true }: Props) => {
  const baseClassName = "BodsEmptyState";
  const classes = classnames(
    baseClassName,
    showBorder ? `${baseClassName}--border` : ""
  );

  return (
    <div className={classes}>
      <div className={`${baseClassName}__content`}>
        {title && (
          <Text tag="h6" className={`${baseClassName}__title`}>
            {title}
          </Text>
        )}
        {text && <Text className={`${baseClassName}__text`}>{text}</Text>}
        {button && <div className={`${baseClassName}__button`}>{button}</div>}
      </div>
    </div>
  );
};

export default BodsEmptyState;
