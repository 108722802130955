import classnames from "classnames";

// Styles
import "./BodsSection.scss";

type Props = {
  children: React.ReactNode;
  width?: "full" | "full-gutter" | "boxed";
};

const BodsSection = ({ children, width = "boxed" }: Props) => {
  const baseClassName = "BodsSection";
  const classes = classnames(baseClassName, `${baseClassName}--${width}`);

  return <section className={classes}>{children}</section>;
};

export default BodsSection;
