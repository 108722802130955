import classnames from "classnames";

// Components
import { InfoCard, Text } from "../../design_system/components";

type Props = {
  title?: string;
  center?: boolean;
  showTitle?: boolean;
  className?: string;
};

const PhoneInfoCard = ({
  title = "Phone",
  center = false,
  showTitle = true,
  className,
}: Props) => {
  const baseClassName = "PhoneInfoCard";
  const classes = classnames(baseClassName, className);

  return (
    <InfoCard title={title} center={center} showTitle={showTitle}>
      <Text>
        <a href="tel://415-273-9676" className={classes}>
          (415) 273-9676
        </a>
      </Text>
    </InfoCard>
  );
};

export default PhoneInfoCard;
