import { useState, useEffect, useCallback } from "react";
import classnames from "classnames";

import "./BodsBackdrop.scss";

export interface Props {
  animation?: boolean;
  onClick?(): void;
  transparent?: boolean;
}

const classNamePrefix = "BodsBackdrop";

const BodsBackdrop: React.FC<Props> = ({
  animation = true,
  onClick,
  transparent = false,
}: Props) => {
  const [isBackdropRender, setIsBackdropRender] = useState(!animation);
  const classes = classnames(
    classNamePrefix,
    { [`${classNamePrefix}--open`]: isBackdropRender },
    transparent && `${classNamePrefix}--transparent`
  );

  useEffect(() => {
    if (animation) setTimeout(() => setIsBackdropRender(true));
  }, [animation]);

  const handleClick = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  return <div className={classes} onClick={handleClick} />;
};

export default BodsBackdrop;
