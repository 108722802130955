// @ts-nocheck
import { NavLink } from "react-router-dom";

// Constants
import { Routes } from "../../constants/Routes.constants";

// Types
import { JobType } from "../../types/Job.type";

// Components
import {
  Container,
  Column,
  Row,
  Text,
  ContentfulRichTextRenderer,
} from "../../design_system/components";

// Styles
import "./JobDetails.scss";

type Props = {
  job: JobType;
};

const JobDetails = ({ job }: Props) => {
  const baseClassName = "JobDetails";
  return (
    <Container paddingY={3} className={baseClassName}>
      <Row>
        <Column md="hidden" lg="2" xl="2" />
        <Column md="12" lg="8" xl="8">
          <NavLink to={Routes.JOBS_PAGE.path}>Back to Jobs</NavLink>
          <Text tag="h2">{job.title}</Text>
          <ContentfulRichTextRenderer data={job.jobDetails} />
          <Text className={`${baseClassName}__apply`}>
            To apply for a role below, send your resume to&nbsp;
            <a
              href={`mailto:alex@blueoakbrewing.com?subject=${job.title} Position`}
            >
              alex@blueoakbrewing.com
            </a>
            .
          </Text>
        </Column>
        <Column md="hidden" lg="2" xl="2" />
      </Row>
    </Container>
  );
};

export default JobDetails;
