import React, { PureComponent } from "react";
import { createPortal } from "react-dom";

import { globalIdGenerator } from "../../utils/utils";

const portal = {
  props: ["data-portal-id"],
  selector: "[data-portal-id]",
};

export interface PortalProps {
  children?: React.ReactNode;
  idPrefix?: string;
  onPortalCreated?(): void;
}

interface State {
  isMounted: boolean;
}

const getUniqueID = globalIdGenerator("portal");

// eslint-disable-next-line
function noop() {}

export class Portal extends PureComponent<PortalProps, State> {
  static defaultProps = { idPrefix: "" };

  state: State = { isMounted: false };

  private portalNode: HTMLElement | null = null;

  private portalId =
    this.props.idPrefix !== ""
      ? `${this.props.idPrefix}-${getUniqueID()}`
      : getUniqueID();

  componentDidMount() {
    this.portalNode = document.createElement("div");
    this.portalNode.setAttribute(portal.props[0], this.portalId);
    document.body.appendChild(this.portalNode);
    this.setState({ isMounted: true });
  }

  componentDidUpdate(_: PortalProps, prevState: State) {
    const { onPortalCreated = noop } = this.props;
    if (!prevState.isMounted && this.state.isMounted) {
      onPortalCreated();
    }
  }

  componentWillUnmount() {
    if (this.portalNode) {
      document.body.removeChild(this.portalNode);
    }
  }

  render() {
    return this.portalNode && this.state.isMounted
      ? createPortal(this.props.children, this.portalNode)
      : null;
  }
}
