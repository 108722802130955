import { useState } from "react";
import classnames from "classnames";

// Styles
import "./BodsTooltip.scss";

type Props = {
  children: React.ReactNode;
  direction?: "right" | "left" | "top" | "bottom";
  delay?: number;
  content: string;
};

const BodsTooltip = ({
  children,
  direction = "bottom",
  delay = 200,
  content,
}: Props) => {
  // @ts-ignore
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    // @ts-ignore
    clearInterval(timeout);
    setActive(false);
  };

  const baseClassName = "BodsTooltip";

  const tipClasses = classnames(
    `${baseClassName}__tip`,
    ` ${baseClassName}__tip--${direction}`
  );

  return (
    <div
      className={`${baseClassName}__wrapper`}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && <div className={tipClasses}>{content}</div>}
    </div>
  );
};

export default BodsTooltip;
