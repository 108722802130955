import classnames from "classnames";

// Components
import { InfoCard, Text } from "../../design_system/components";

type Props = {
  title?: string;
  center?: boolean;
  showTitle?: boolean;
  className?: string;
};

const EmailInfoCard = ({
  title = "Email",
  center = false,
  showTitle = true,
  className,
}: Props) => {
  const baseClassName = "EmailInfoCard";
  const classes = classnames(baseClassName, className);

  return (
    <InfoCard title={title} center={center} showTitle={showTitle}>
      <Text>
        <a href="mailto:info@blueoakbrewing.com" className={classes}>
          info@blueoakbrewing.com
        </a>
      </Text>
    </InfoCard>
  );
};

export default EmailInfoCard;
