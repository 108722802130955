import "./BodsButtonGroup.scss";

export interface Props {
  children: React.ReactNode | React.ReactElement | React.ReactElement[];
}

const BodsButtonGroup: React.FC<Props> = ({ children }: Props) => {
  const baseClassName = "BodsButtonGroup";

  return <div className={baseClassName}>{children}</div>;
};

export default BodsButtonGroup;
