// Constants
import { Routes } from "../../constants/Routes.constants";

// Hooks
import { useEventsCalendar } from "../../hooks/use-events-calendar";
import { useResponsiveScreenWidth } from "../../design_system/hooks/use-responsive-screen-width";

// Utils
import { convertToMoment, isInThePast } from "../../utils/DateTime.utils";

// Components
import { Container, BodsEmptyState } from "../../design_system/components";
import EventCardList from "../EventCardList/EventCardList";
import EventCalendar from "../EventCalendar/EventCalendar";
import CalendarEventCard from "./CalendarEventCard";

const EventsPage = () => {
  const { isLoading, data = [] } = useEventsCalendar();

  const { isLarge } = useResponsiveScreenWidth({ isAtLeast: true });

  const showEmpty = !isLoading && data?.length === 0;

  const baseClassName = "EventsPage";

  return (
    <div className={baseClassName}>
      <Container paddingY={2}>
        {isLoading && <>Loading...</>}
        {showEmpty ? (
          <BodsEmptyState
            title="No events scheduled at this time"
            text="Check back often, we're always booking new gigs!"
            showBorder={false}
          />
        ) : (
          <>
            {isLarge ? (
              <EventCalendar events={data} eventComponent={CalendarEventCard} />
            ) : (
              <EventCardList
                events={data.filter(
                  (event) =>
                    !isInThePast(convertToMoment(event.date).add(1, "days"))
                )}
                route={Routes.EVENT_DETAILS_PAGE}
                showLink={false}
                showShortDescription
              />
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default EventsPage;
