// Types
import { EventType } from "../../types";

// Utils
import { formatDate } from "../../utils/DateTime.utils";

// Components
import {
  Container,
  Row,
  Column,
  Text,
  ContentfulRichTextRenderer,
} from "../../design_system/components";
import IconAttribute from "../IconAttribute/IconAttribute";

// Styles
import "./EventDetails.scss";

type Props = {
  event: EventType;
};

const EventDetails = ({ event }: Props) => {
  const fullDate = formatDate(event.date, "dddd, MMMM Do YYYY");

  const baseClassName = "EventDetails";

  const attributes = (
    <div className={`${baseClassName}__right-details`}>
      <div className={`${baseClassName}__right-details__attribute`}>
        <IconAttribute icon="calendar" value={fullDate} />
      </div>
      <div className={`${baseClassName}__right-details__attribute`}>
        <IconAttribute icon="clock" value={event.time} />
      </div>
      {event.websiteLink && (
        <div className={`${baseClassName}__right-details__attribute`}>
          <IconAttribute icon="globe" value={event.websiteLink}>
            <a
              href={event.websiteLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {event.websiteLink}
            </a>
          </IconAttribute>
        </div>
      )}
    </div>
  );

  return (
    <>
      <Container className={baseClassName}>
        <Row>
          <Column md="hidden" lg="1" xl="1"></Column>
          <Column md="12" lg="10" xl="10">
            <div className={`${baseClassName}__hero`}>
              <div
                className={`${baseClassName}__hero__background`}
                style={{ backgroundImage: `url(${event.image?.url})` }}
              ></div>
              <img
                className={`${baseClassName}__hero__image`}
                src={event.image?.url}
                alt={event.title}
              />
            </div>
          </Column>
          <Column md="hidden" lg="1" xl="1"></Column>
        </Row>
      </Container>
      <Container className={`${baseClassName}__details`}>
        <Row>
          <Column md="hidden" lg="1" xl="1"></Column>
          <Column md="12" lg="6" xl="6">
            <div>
              <Text tag="h1" bold>
                {event.title}
              </Text>
            </div>
            <div className={`${baseClassName}__details__attributes`}>
              {attributes}
            </div>
            <div>
              <ContentfulRichTextRenderer data={event.description} />
            </div>
          </Column>
          <Column md="hidden" lg="4" xl="4">
            {attributes}
          </Column>
          <Column md="hidden" lg="1" xl="1"></Column>
        </Row>
      </Container>
    </>
  );
};

export default EventDetails;
