// @ts-nocheck
import { useQuery } from "@tanstack/react-query";

// GQL
import { gqlClient } from "../gql/gqlClient";
import { bannerAlertCollection } from "../gql/bannerAlertCollection.query";

export const useAlertBanner = () => {
  const { isLoading, data = [] } = useQuery(["alert-banner"], () =>
    gqlClient
      .request(bannerAlertCollection)
      .then((data) => data.bannerCollection.items)
  );

  const hasAlert = data?.length > 0;
  return { isLoading, hasAlert, alert: data[0] };
};
