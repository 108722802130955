// @ts-nocheck
// Types
import { FaqType } from "../../types/Faq.type";

// Components
import {
  Text,
  ContentfulRichTextRenderer,
} from "../../design_system/components";

// Styles
import "./FaqListItem.scss";

type Props = {
  faq: FaqType;
};

const FaqListItem = ({ faq }: Props) => {
  const baseClassName = "FaqListItem";

  return (
    <div className={baseClassName}>
      <Text tag="h6" className={`${baseClassName}__title`} bold>
        {faq.title}
      </Text>
      <ContentfulRichTextRenderer data={faq.text} />
      {/* <Text tag="p" className={`${baseClassName}__text`}>{faq.text}</Text> */}
    </div>
  );
};

export default FaqListItem;
