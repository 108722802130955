// Constants
import { Routes } from "../../constants/Routes.constants";

// Components
import NavigationItem from "./NavigationItem";

// Styles
import "./NavigationMobile.scss";

type Props = {
  onClick?: Function;
};

const NavigationMobile = ({ onClick }: Props) => {
  const baseClassName = "NavigationMobile";

  return (
    <div className={baseClassName}>
      <NavigationItem route={Routes.STORE_PAGE} onClick={onClick} />
      <NavigationItem route={Routes.TAPROOM_PAGE} onClick={onClick} />
      <NavigationItem route={Routes.FOOD_TRUCK_PAGE} onClick={onClick} />
      <NavigationItem route={Routes.EVENTS_PAGE} onClick={onClick} />
      {/* <NavigationItem route={Routes.ABOUT_PAGE} onClick={onClick} /> */}
      <NavigationItem route={Routes.JOBS_PAGE} onClick={onClick} />
      <NavigationItem route={Routes.PRIVATE_EVENTS_PAGE} onClick={onClick} />
      <NavigationItem route={Routes.CONTACT_PAGE} onClick={onClick} />
    </div>
  );
};

export default NavigationMobile;
