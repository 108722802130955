// components
import { BodsLabelled, BodsInlineError } from "../";

// Styles
import "./BodsInput.scss";

type TypeType = "text" | "email";

type Props = {
  label?: string;
  id: string;
  name: string;
  type?: TypeType;
  required?: boolean;
  error?: string;
};

const BodsInput = ({
  id,
  label,
  name,
  type = "text",
  required = false,
  error = "",
  ...rest
}: Props) => {
  const baseClssName = "BodsInput";

  return (
    <BodsLabelled id={id} label={label ? label : name}>
      <input
        className={baseClssName}
        id={id}
        name={name}
        type={type}
        required={required}
        {...rest}
      />
      {error && <BodsInlineError fieldID={id} message={error} />}
    </BodsLabelled>
  );
};

export default BodsInput;
