// Constants
import { Routes } from "../../constants/Routes.constants";

// Components
import NavigationItem from "./NavigationItem";

// Styles
import "./Navigation.scss";

const Navigation = () => {
  const baseClassName = "Navigation";

  return (
    <div className={baseClassName}>
      <NavigationItem route={Routes.STORE_PAGE} />
      <NavigationItem route={Routes.TAPROOM_PAGE} />
      <NavigationItem route={Routes.FOOD_TRUCK_PAGE} />
      <NavigationItem route={Routes.EVENTS_PAGE} />
    </div>
  );
};

export default Navigation;
