// Types
import { BodsCalendarEventType } from "../../design_system/components/calendar";

// Hooks
import { useFoodTruckCalendar } from "../../hooks/use-food-truck-calendar";
import { useToggle } from "../../hooks/use-toggle";

// Components
import EventCard from "../EventCard/EventCard";
import {
  BodsModal,
  BodsModalHeader,
  BodsModalBody,
} from "../../design_system/components";

// Styles
import "./CalendarFoodTruckCard.scss";

type Props = {
  event: BodsCalendarEventType;
};

const CalendarFoodTruckCard = ({ event }: Props) => {
  const { data = [] } = useFoodTruckCalendar();

  const [isOpen, toggleIsOpen] = useToggle(false);

  const eventData = data && data.find((e) => e.sys.id === event.id)!;

  const baseClassName = "CalendarFoodTruckCard";
  return (
    <>
      <div onClick={toggleIsOpen} role="button">
        <EventCard
          event={eventData}
          slim
          bordered={false}
          showShortDescription
        />
      </div>
      {isOpen && (
        <BodsModal isOpen={isOpen} onToggle={toggleIsOpen}>
          <BodsModalHeader onToggle={toggleIsOpen} />
          <BodsModalBody>
            <div className={`${baseClassName}__modal-card`}>
              <EventCard event={eventData} bordered={false} />
            </div>
          </BodsModalBody>
        </BodsModal>
      )}
    </>
  );
};

export default CalendarFoodTruckCard;
