import classnames from "classnames";

// Styles
import "./BodsImageCard.scss";

type Props = {
  // @ts-ignore
  image?;
  children: React.ReactNode;
  hover?: boolean;
  className?: string;
};

const BodsImageCard = ({
  image,
  children,
  className,
  hover = false,
}: Props) => {
  const styles = {
    background:  image && `url("${image}") center center`,
  };

  const baseClassName = "BodsImageCard";
  const classes = classnames(
    baseClassName,
    hover && `${baseClassName}--hover`,
    className
  );

  return (
    <div className={classes} style={styles}>
      <div className={`${baseClassName}__content`}>{children}</div>
    </div>
  );
};

export default BodsImageCard;
