// Styles
import "./BodsBanner.scss";

type Props = {
  text: string;
  linkText?: string;
  link?: string;
};

const BodsBanner = ({ text, linkText, link }: Props) => {
  const showLink = Boolean(linkText) && Boolean(link);
  const baseClassName = "BodsBanner";
  return (
    <div className={baseClassName}>
      {text}
      {showLink && (
        <a
          href={link}
          className={`${baseClassName}__link`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {linkText}
        </a>
      )}
    </div>
  );
};

export default BodsBanner;
