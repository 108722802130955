type Props = {
  src: string;
  alt?: string;
};

const BodsImage = ({ src, alt = "Blue Oak Brewing" }: Props) => {
  return <img src={src} alt={alt} />;
};

export default BodsImage;
