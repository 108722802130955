import BodsSection from "../../design_system/components/section/BodsSection";

import {
  BodsButton,
  BodsIcon,
  BodsCalendar,
} from "../../design_system/components";

const DesignPage = () => {
  return (
    <div>
      <BodsSection>
        <h1>H1</h1>
        <h2>H2</h2>
        <h3>H3</h3>
        <h4>H4</h4>
        <h5>H5</h5>
        <h6>H6</h6>
      </BodsSection>
      <BodsSection>
        <BodsButton>test</BodsButton>
        <BodsButton>test 2</BodsButton>
        <BodsButton icon>
          <BodsIcon name="facebook" />
        </BodsButton>
        <BodsButton icon color="gray">
          <BodsIcon name="facebook" />
        </BodsButton>
        <BodsButton icon color="tertiary">
          <BodsIcon name="facebook" />
        </BodsButton>
      </BodsSection>
      <BodsSection>
        <BodsCalendar />
      </BodsSection>
    </div>
  );
};

export default DesignPage;
