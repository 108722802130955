const prefix = "outline";
const iconName = "menu";
const viewBox = "0 0 20 12";

const svg = (color) => (
  <g
    id="Symbols"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g transform="translate(-2.000000, -6.000000)">
      <g id="menu">
        <g>
          <path
            d="M3,7.75 C2.59,7.75 2.25,7.41 2.25,7 C2.25,6.59 2.59,6.25 3,6.25 L21,6.25 C21.41,6.25 21.75,6.59 21.75,7 C21.75,7.41 21.41,7.75 21,7.75 L3,7.75 Z"
            id="Vector"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M3,12.75 C2.59,12.75 2.25,12.41 2.25,12 C2.25,11.59 2.59,11.25 3,11.25 L21,11.25 C21.41,11.25 21.75,11.59 21.75,12 C21.75,12.41 21.41,12.75 21,12.75 L3,12.75 Z"
            id="Vector"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M3,17.75 C2.59,17.75 2.25,17.41 2.25,17 C2.25,16.59 2.59,16.25 3,16.25 L21,16.25 C21.41,16.25 21.75,16.59 21.75,17 C21.75,17.41 21.41,17.75 21,17.75 L3,17.75 Z"
            id="Vector"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path
            d="M24,0 L24,24 L0,24 L0,0 L24,0 Z"
            id="Vector"
            opacity="0"
          ></path>
        </g>
      </g>
    </g>
  </g>
);

export const menu = {
  prefix,
  iconName, // Any name you like
  svg, // svg path data
  viewBox,
};
