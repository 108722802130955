// Types
import { FaqType } from "../../types/Faq.type";

// Components
import FaqListItem from "./FaqListItem";

type Props = {
  faqs: FaqType[];
};

const FaqList = ({ faqs }: Props) => {
  if (!faqs || faqs?.length === 0) return null;

  const orderedFaqs = faqs[0].hasOwnProperty('order') ? faqs.sort((a, b) => {
    if(a.order === null) return 1;
    if(b.order === null) return -1;

    // @ts-ignore
    return a.order - b.order;
  }) : faqs;

  const baseClassName = "FaqList";

  return (
    <div className={baseClassName}>
      {orderedFaqs.map((faq) => (
        <FaqListItem key={`faq-list-item-${faq.sys.id}`} faq={faq} />
      ))}
    </div>
  );
};

export default FaqList;
