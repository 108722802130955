import { NavLink } from "react-router-dom";

// Constants
import { Routes } from "../../constants/Routes.constants";

// Types
import { RouteType } from "../../constants/Routes.constants";

// Images
import Logo from "../../images/logo-white-left-aligned.svg";

// Components
import {
  Container,
  Row,
  Column,
  Text,
  InfoCard,
  Seperator,
} from "../../design_system/components";
import BodsImage from "../../design_system/components/image/BodsImage";
import SocialLinks from "../SocialLinks/SocialLinks";
import AddressInfoCard from "../AddressInfoCard/AddressInfoCard";
import EmailInfoCard from "../EmailInfoCard/EmailInfoCard";
import PhoneInfoCard from "../PhoneInfoCard/PhoneInfoCard";

// Styles
import "./Footer.scss";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const baseClassName = "Footer";

  const NavigationItem = ({ route }: { route: RouteType }) => {
    return (
      <Text>
        <NavLink
          className={`${baseClassName}__link`}
          to={route.path}
          exact={route.exact}
        >
          {route.label}
        </NavLink>
      </Text>
    );
  };

  return (
    <Container fluid className={baseClassName}>
      <Container>
        <Row>
          <Column
            sm="12"
            md="4"
            lg="3"
            xl="3"
            className={`${baseClassName}__column`}
          >
            <div className={`${baseClassName}__logo`}>
              <BodsImage src={Logo} />
            </div>
          </Column>
          <Column
            sm="hidden"
            md="hidden"
            lg="3"
            xl="3"
            className={`${baseClassName}__column`}
          >
            <Row>
              <Column size={6}>
                <NavigationItem route={Routes.STORE_PAGE} />
                <NavigationItem route={Routes.BEER_PAGE} />
                <NavigationItem route={Routes.FOOD_TRUCK_PAGE} />
                <NavigationItem route={Routes.EVENTS_PAGE} />
              </Column>
              <Column size={6}>
                <NavigationItem route={Routes.PRIVATE_EVENTS_PAGE} />
                <NavigationItem route={Routes.TAPROOM_PAGE} />
                <NavigationItem route={Routes.CONTACT_PAGE} />
                {/* <NavigationItem route={Routes.ABOUT_PAGE} /> */}
                <NavigationItem route={Routes.JOBS_PAGE} />
              </Column>
            </Row>
          </Column>
          <Column
            sm="12"
            md="4"
            lg="3"
            xl="3"
            className={`${baseClassName}__column`}
          >
            <InfoCard title="Get In Touch" center>
              <AddressInfoCard
                showTitle={false}
                className={`${baseClassName}__link`}
              />
              <div className={`${baseClassName}__seperator`}>
                <Seperator />
              </div>
              <EmailInfoCard
                showTitle={false}
                className={`${baseClassName}__link`}
              />
              <div className={`${baseClassName}__seperator`}>
                <Seperator />
              </div>
              <PhoneInfoCard
                showTitle={false}
                className={`${baseClassName}__link`}
              />
            </InfoCard>
          </Column>
          <Column
            sm="12"
            md="4"
            lg="3"
            xl="3"
            className={`${baseClassName}__column`}
          >
            <InfoCard title="Get Social" center>
              <SocialLinks color="white" size="32" />
            </InfoCard>
          </Column>
        </Row>
        <Row>
          <Column size={12}>
            <hr />
          </Column>
          <Column size={12}>©{currentYear} Blue Oak Brewing Co.</Column>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
