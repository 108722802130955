import { useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";

// Types
import { BodsCalendarEventType } from "./types";

// Components
import { BodsButton } from "../button";
import { BodsIcon } from "../icon";
import { Text } from "../text";

// Styles
import "./BodsCalendar.scss";

const localizer = momentLocalizer(moment);

type Props = {
  events?: BodsCalendarEventType[];
  eventComponent?: any;
};

const BodsCalendar = ({ events = [], eventComponent }: Props) => {
  const baseClassName = "BodsCalendar";
  const Toolbar = ({
    label,
    onNavigate,
  }: {
    label: string;
    onNavigate: Function;
  }) => {
    return (
      <div className={`${baseClassName}__toolbar`}>
        <BodsButton onClick={() => onNavigate("PREV")} color="gray" small>
          <BodsIcon name="chevronLeft" size="16" />
        </BodsButton>
        <Text tag="h4" className={`${baseClassName}__toolbar__title`}>
          {label}
        </Text>
        <BodsButton onClick={() => onNavigate("NEXT")} color="gray" small>
          <BodsIcon name="chevronRight" size="16" />
        </BodsButton>
      </div>
    );
  };
  const components = useMemo(
    () => ({
      toolbar: Toolbar,

      month: {
        event: eventComponent && eventComponent,
      },
    }),
    [eventComponent]
  );
  return (
    <Calendar
      components={components}
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      views={{
        month: true,
      }}
      className={baseClassName}
      // toolbar= {false}
    />
  );
};

export default BodsCalendar;
