import { Link, generatePath } from "react-router-dom";
import classnames from "classnames";

// Types
import { EventType } from "../../types";
import { RouteType } from "../../constants/Routes.constants";

// Utils
import { formatDate } from "../../utils/DateTime.utils";

// Components
import {
  BodsImageCard,
  BodsCard,
  BodsCardBody,
  Text,
  ContentfulRichTextRenderer,
} from "../../design_system/components";
import DayBlock from "../DayBlock/DayBlock";
import IconAttribute from "../IconAttribute/IconAttribute";

// Styles
import "./EventCard.scss";

type Props = {
  event: EventType;
  route?: RouteType;
  showLink?: boolean;
  className?: string;
  slim?: boolean;
  showShortDescription?: boolean;
  bordered?: boolean;
};
const EventCard = ({
  event,
  route,
  showLink = true,
  className,
  slim = false,
  showShortDescription = false,
  bordered = true,
}: Props) => {
  const fullDate = formatDate(event.date, "dddd, MMMM Do YYYY");

  const hasLink = Boolean(event?.websiteLink);
  const hasShortDesscription = showShortDescription && event.shortDescription;

  const baseClassName = "EventCard";
  const classes = classnames(
    baseClassName,
    slim && `${baseClassName}--slim`,
    className
  );

  const card = (
    <BodsCard className={`${baseClassName}__card`} bordered={bordered}>
      <BodsCardBody padding="0" className={`${baseClassName}__image-wrapper`}>
        <BodsImageCard
          image={event.image?.url}
          className={`${baseClassName}__image-wrapper__image`}
        >
          <div style={{ padding: slim ? "3rem 0" : "7rem 0" }}></div>
        </BodsImageCard>
        {!slim && (
          <div className={`${baseClassName}__image-wrapper__day`}>
            <DayBlock date={event.date} />
          </div>
        )}
      </BodsCardBody>
      <BodsCardBody padding={slim ? "0.25" : "1"}>
        <Text
          tag={slim ? "p" : "h5"}
          bold
          className={`${baseClassName}__title`}
        >
          {event.title}
        </Text>
        {!slim && (
          <div className={`${baseClassName}__attribute`}>
            <IconAttribute icon="calendar" value={fullDate} />
          </div>
        )}
        <div className={`${baseClassName}__attribute`}>
          <IconAttribute icon="clock" value={event.time} />
        </div>
        <div className={`${baseClassName}__description`}>
          {hasShortDesscription ? (
            <Text className={`${baseClassName}__description__text`}>
              {event.shortDescription}
            </Text>
          ) : (
            <ContentfulRichTextRenderer data={event.description} />
          )}
        </div>
        {!slim && hasLink && showLink && (
          <div className={`${baseClassName}__link`}>
            <a
              href={event.websiteLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {event.websiteLink}
            </a>
          </div>
        )}
      </BodsCardBody>
    </BodsCard>
  );

  return (
    <>
      {route ? (
        <Link
          to={generatePath(route.path, { id: event.sys.id })}
          className={classes}
        >
          {card}
        </Link>
      ) : (
        <div className={classes}>{card}</div>
      )}
    </>
  );
};

export default EventCard;
