import classnames from "classnames";

//  Components
import { BodsIcon, Text } from "../../../design_system/components";

// Styles
import "./BodsAlert.scss";

type Props = {
  text: string;
  position?: "left" | "center";
  linkText?: string;
  link?: string;
};

const BodsAlert = ({ text, position = "center", linkText, link }: Props) => {
  const showLink = Boolean(linkText) && Boolean(link);

  const baseClassName = "BodsAlert";
  const classes = classnames(baseClassName, `${baseClassName}--${position}`);

  return (
    <div className={classes}>
      <BodsIcon name="danger" />
      <Text className={`${baseClassName}__text`}>{text}</Text>
      {showLink && (
        <>
          &nbsp;
          <a
            href={link}
            className={`${baseClassName}__link`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {linkText}
          </a>
        </>
      )}
    </div>
  );
};

export default BodsAlert;
