import { useForm, Controller } from "react-hook-form";

// Hooks
import { useToggle } from "../../hooks/use-toggle";

// components
import {
  Container,
  Row,
  Column,
  BodsForm,
  BodsInput,
  BodsTextarea,
  BodsButton,
  VisuallyHidden,
  BodsEmptyState,
} from "../../design_system/components";
import AddressInfoCard from "../AddressInfoCard/AddressInfoCard";
import EmailInfoCard from "../EmailInfoCard/EmailInfoCard";
import PhoneInfoCard from "../PhoneInfoCard/PhoneInfoCard";

// Styles
import "./ContactPage.scss";

const ContactPage = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      message: "",
    },
  });

  const [isSubmitted, toggleIsSubmitted] = useToggle(false);

  // @ts-ignore
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  // @ts-ignore
  const onSubmit = (data) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...data }),
    })
      .then(() => toggleIsSubmitted())
      .catch((error) => console.log(error));
  };

  const isValidEmail = (email: string) => {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(String(email).toLowerCase());
  };
  const baseClassName = "ContactPage";

  return (
    <div className={baseClassName}>
      <Container paddingY={3}>
        <Row>
          <Column md="hidden" lg="2" xl="2" />
          <Column sm="12" md="8" lg="5" xl="5">
            {isSubmitted ? (
              <BodsEmptyState
                title="Thanks for dropping us a note!"
                text="We'll get back to you soon."
              />
            ) : (
              <BodsForm
                id="contact"
                name="contact"
                netlifyHoneypotId="nope"
                // @ts-ignore
                isNetlify
                onSubmit={handleSubmit(onSubmit)}
              >
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <BodsInput
                      label="Name"
                      id="name"
                      {...field}
                      error={errors.name && "Please add your name"}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
                <VisuallyHidden>
                  <BodsInput label="nope" id="nope" name="nope" />
                </VisuallyHidden>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <BodsInput
                      label="Email"
                      id="email"
                      {...field}
                      error={errors.email && "Please add your email"}
                    />
                  )}
                  rules={{
                    validate: {
                      isEmail: isValidEmail,
                    },
                  }}
                />
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <BodsTextarea
                      label="Message"
                      id="message"
                      rows={10}
                      {...field}
                      error={errors.message && "Please add your message"}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
                <BodsButton type="submit">Send Message</BodsButton>
              </BodsForm>
            )}
          </Column>
          <Column md="hidden" lg="1" xl="1" />
          <Column sm="12" md="4" lg="4" xl="4">
            <div className={`${baseClassName}__info-cards`}>
              <div className={`${baseClassName}__info-cards__card`}>
                <AddressInfoCard title="Visit Us" />
              </div>
              <div className={`${baseClassName}__info-cards__card`}>
                <EmailInfoCard />
              </div>
              <div className={`${baseClassName}__info-cards__card`}>
                <PhoneInfoCard />
              </div>
            </div>
          </Column>
          <Column sm="hidden" md="hidden" lg="2" xl="2" />
        </Row>
      </Container>
    </div>
  );
};

export default ContactPage;
