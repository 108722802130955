import { isValidElement } from "react";

// In development, we compare based on the name of the function because
// React Hot Loader proxies React components in order to make updates. In
// production we can simply compare the components for equality.
const isComponent = (
  AComponent: React.ComponentType<any>,
  AnotherComponent: React.ComponentType<any>
) => AComponent === AnotherComponent;

// // Checks whether `element` is a React element of type `Component` (or one of
// // the passed components, if `Component` is an array of React components).
// export function isElementOfType<TProps>(
//   element: React.ReactElement | React.ReactNode | null | undefined,
//   Component: React.ComponentType<TProps> | React.ComponentType<TProps>[]
// ): boolean {
//   if (
//     element == null ||
//     !isValidElement(element) ||
//     typeof element.type === "string"
//   ) {
//     return false;
//   }

//   const { type: defaultType } = element;
//   // Type override allows components to bypass default wrapping behavior. Ex: Stack, ResourceList...
//   // See https://github.com/Shopify/app-extension-libs/issues/996#issuecomment-710437088
//   const overrideType = element.props?.__type__;
//   const type = overrideType || defaultType;
//   const Components = Array.isArray(Component) ? Component : [Component];

//   return Components.some(
//     (AComponent) => typeof type !== "string" && isComponent(AComponent, type)
//   );
// }

// Checks whether `element` is a React element of type `Component` (or one of
// the passed components, if `Component` is an array of React components).
export function isElementOfType<TProps>(
  element: React.ReactNode | null | undefined,
  Component: React.ComponentType<TProps> | React.ComponentType<TProps>[]
): boolean {
  if (
    element == null ||
    !isValidElement(element) ||
    typeof element.type === "string"
  ) {
    return false;
  }

  const { type: defaultType } = element;
  // Type override allows components to bypass default wrapping behavior. Ex: Stack, ResourceList...
  // See https://github.com/Shopify/app-extension-libs/issues/996#issuecomment-710437088
  const overrideType = element.props?.__type__;
  const type = overrideType || defaultType;
  const Components = Array.isArray(Component) ? Component : [Component];

  return Components.some(
    (AComponent) => typeof type !== "string" && isComponent(AComponent, type)
  );
}

export function globalIdGenerator(prefix = "") {
  let index = 0;
  // eslint-disable-next-line
  return () => `Bods-${prefix}-${(index += 1)}`;
}
