import { NavLink } from "react-router-dom";

// Types
import { RouteType } from "../../constants/Routes.constants";

// Styles
import "./NavigationItem.scss";

type Props = {
  route: RouteType;
  onClick?: Function;
};

const NavigationItem = ({ route, onClick: onClickCallback }: Props) => {
  const onClick = () => {
    onClickCallback && onClickCallback();
  };

  const baseClassName = "NavigationItem";

  return (
    <NavLink
      to={route.path}
      // exact={route.exact}
      className={baseClassName}
      activeClassName={`${baseClassName}--active`}
      onClick={onClick}
    >
      {route.label}
    </NavLink>
  );
};

export default NavigationItem;
