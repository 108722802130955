import { gql } from "graphql-request";

export const taproomFaqCollection = gql`
  {
    taproomFaqCollection(where: {
      contentfulMetadata: {
        tags_exists: true
        tags: {
            id_contains_some: ["taproom"]
        }
    }
    }) {
      items {
        title
        order
        text {
          json
        }
        sys {
          id
        }
      }
    }
  }
`;
