// @ts-nocheck
import { useQuery } from "@tanstack/react-query";

// GQL
import { gqlClient } from "../gql/gqlClient";
import { taproomFaqCollection } from "../gql/taproomFaqCollection.query";

export const useTaproomFaq = () => {
  return useQuery(["taproomFaq"], () =>
    gqlClient.request(taproomFaqCollection).then((data) => data.taproomFaqCollection.items)
  );
};
