import { PhotoGalleryPhotoType } from "./types";

type Props = {
  photo: PhotoGalleryPhotoType;
};

const PhotoGalleryItem = ({ photo }: Props) => {
  return (
    <div>
      <img src={photo.url} alt="" />
    </div>
  );
};

export default PhotoGalleryItem;
