const prefix = "outline";
const iconName = "facebook";
const viewBox = "0 0 22 22";

const svg = (color) => (
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-1.000000, -1.000000)">
      <g>
        <g>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z"></path>
          <path
            d="M10.25,22.75 L10.25,15.5 L9.29998779,15.5 C8.71998781,15.5 8.25,15.0300122 8.25,14.4500122 L8.25,12.5499878 C8.25,11.9699878 8.71998781,11.5 9.29998779,11.5 L10.25,11.5 L10.25,9 C10.25,6.93000007 11.9300001,5.25 14,5.25 L16.7000122,5.25 C17.2800122,5.25 17.75,5.71998781 17.75,6.29998779 L17.75,8.70001221 C17.75,9.28001219 17.2800122,9.75 16.7000122,9.75 L14.75,9.75 L14.75,11.5 L16.6300049,11.5 C16.9500049,11.5 17.2400024,11.6400049 17.4400024,11.8800049 C17.6400024,12.1200049 17.7200037,12.44 17.6600037,12.75 L17.2799988,14.6499939 C17.1799988,15.1399939 16.75,15.4899902 16.25,15.4899902 L14.75,15.4899902 L14.75,22.75 L14.75,22.75 L10.25,22.75 Z M13.25,21.25 L13.25,14 L15.8900146,14 L16.0899963,13 L13.2600098,13 L13.2600098,9.29998779 C13.2600098,8.71998781 13.7299976,8.25 14.3099976,8.25 L16.2600098,8.25 L16.2600098,6.75 L14,6.75 C12.76,6.75 11.75,7.75999999 11.75,9 L11.75,13 L9.75,13 L9.75,14 L11.75,14 L11.75,21.25 L13.25,21.25 Z"
            fill={color}
          ></path>
          <path
            d="M9,22.75 C3.57000017,22.75 1.25,20.4299998 1.25,15 L1.25,9 C1.25,3.57000017 3.57000017,1.25 9,1.25 L15,1.25 C20.4299998,1.25 22.75,3.57000017 22.75,9 L22.75,15 C22.75,20.4299998 20.4299998,22.75 15,22.75 L9,22.75 Z M2.75,9 L2.75,15 C2.75,19.6100001 4.38999987,21.25 9,21.25 L15,21.25 C19.6100001,21.25 21.25,19.6100001 21.25,15 L21.25,9 C21.25,4.38999987 19.6100001,2.75 15,2.75 L9,2.75 C4.38999987,2.75 2.75,4.38999987 2.75,9 Z"
            fill={color}
          ></path>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" opacity="0"></path>
        </g>
      </g>
    </g>
  </g>
);

export const facebook = {
  prefix,
  iconName, // Any name you like
  svg, // svg path data
  viewBox,
};
