// Types
import { IconNames } from "../../design_system/components/icon/library";
import { EventType } from "../../types";

// Utils
import { formatDate } from "../../utils/DateTime.utils";

// Components
import { Text } from "../../design_system/components";
import BodsIcon from "../../design_system/components/icon/BodsIcon";

// Styles
import "./EventListItem.scss";

type Props = {
  event: EventType;
  onClick?: Function;
};

const EventListItem = ({ event, onClick }: Props) => {
  const day = formatDate(event.date, "D");
  const month = formatDate(event.date, "MMM");
  const fullDate = formatDate(event.date, "dddd, MMMM Do YYYY");

  const handleOnClick = () => {
    if (onClick) onClick(event);
  };

  const baseClassName = "EventListItem";

  const Attribute = ({ icon, value }: { icon: IconNames; value: string }) => {
    return (
      <div className={`${baseClassName}__details__attribute`}>
        <BodsIcon name={icon} container="square" size="16" />
        <span className={`${baseClassName}__details__attribute__value`}>
          {value}
        </span>
      </div>
    );
  };

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__day-box`}>
        <div className={`${baseClassName}__day-box__month`}>{month}</div>
        <div className={`${baseClassName}__day-box__day`}>{day}</div>
      </div>
      <div className={`${baseClassName}__details`}>
        <div onClick={handleOnClick}>
          <Text tag="h5" className={`${baseClassName}__details__name`}>
            {event.title}
          </Text>
        </div>
        <Attribute icon="calendar" value={fullDate} />
        <Attribute icon="clock" value={event.time} />
      </div>
    </div>
  );
};

export default EventListItem;
