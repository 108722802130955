// Components
import BodsIcon, { Size } from "../../design_system/components/icon/BodsIcon";
import { BodsTooltip } from "../../design_system/components";

// Styles
import "./SocialLinks.scss";

type Props = {
  color?: string;
  size?: Size;
};

const SocialLinks = ({ color, size }: Props) => {
  const baseClassName = "SocialLinks";
  return (
    <div className={baseClassName}>
      <a
        className={`${baseClassName}__link`}
        href="https://www.instagram.com/blueoakbrewingcompany/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BodsTooltip content="Instagram">
          <BodsIcon name="instagram" color={color} size={size} />
        </BodsTooltip>
      </a>
      <a
        className={`${baseClassName}__link`}
        href="https://www.facebook.com/BlueOakBrewingCo/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BodsTooltip content="Facebook">
          <BodsIcon name="facebook" color={color} size={size} />
        </BodsTooltip>
      </a>
      <a
        className={`${baseClassName}__link ${baseClassName}__link--untappd`}
        href="https://untappd.com/BlueOakBrewing"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BodsTooltip content="Untappd">
          <BodsIcon name="untappd" color={color} size={size} />
        </BodsTooltip>
      </a>
    </div>
  );
};

export default SocialLinks;
