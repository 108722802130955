// Styles
import './Seperator.scss';

const Seperator = () => {
  const baseClassName = "Seperator";
  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__dot`} />
      <div className={`${baseClassName}__dot`} />
      <div className={`${baseClassName}__dot`} />
    </div>
  );
};

export default Seperator;
