// Images
import Logo from "../../images/LogoWhiteFullStacked.svg";

// Hooks
import { usePersistentStorageValue } from "../../hooks/use-persistent-storage-value";

// Components
import {
  BodsModal,
  BodsModalBody,
  BodsButton,
  Text,
  BodsButtonGroup,
} from "../../design_system/components";

// Styles
import "./Over21ConfirmationModal.scss";

const Over21ConfirmationModal = () => {
  const [isOver21, setIsOver21] = usePersistentStorageValue(
    "age-verification",
    false
  );

  const handleYesClick = () => {
    setIsOver21(true);
  };

  const handleNoClick = () => {
    const element = document.createElement("a");
    element.href = "https://pbskids.org/arthur/";
    element.target = "_blank";
    element.rel = "noopener noreferrer";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const baseClassName = "Over21ConfirmationModal";

  return (
    <BodsModal size="lg" isOpen={!isOver21} dismissOnBackdrop={false}>
      <BodsModalBody>
        <div className={baseClassName}>
          <div className={`${baseClassName}__logo-wrapper`}>
            <img src={Logo} className={`${baseClassName}__logo-wrapper__img`} alt="Blue Oak Brewing"/>
          </div>
          <div className={`${baseClassName}__details`}>
            <Text tag="h3">
              YOU MUST BE AT LEAST 21 YEARS OLD TO ENTER THIS SITE.
            </Text>
            <Text className={`${baseClassName}__details__question`}>
              Are you 21 or older?
            </Text>
            <BodsButtonGroup>
              <BodsButton onClick={handleYesClick}>Yes</BodsButton>
              <BodsButton color="gray" onClick={handleNoClick}>
                No
              </BodsButton>
            </BodsButtonGroup>
          </div>
        </div>
      </BodsModalBody>
    </BodsModal>
  );
};

export default Over21ConfirmationModal;
