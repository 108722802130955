// Types
import { MenuItemType } from "../../types";

// Components
import BeerListItem from "./BeerListItem";

// Styles
import './BeerList.scss'

type Props = {
  menu: MenuItemType[];
};

const BeerList = ({ menu }: Props) => {
  const baseClassName = "BeerList";

  return (
    <div className={baseClassName}>
      {menu.map((item) => (
        <BeerListItem key={item.id} menuItem={item} />
      ))}
    </div>
  );
};

export default BeerList;
