import { useLocation } from "react-router";

// Constants
import { Routes } from "../../constants/Routes.constants";

// Components
import { Container, Text } from "../../design_system/components";

// Styles
import "./PageTitle.scss";

type Props = {
  title?: string;
};

const PageTitle = ({ title }: Props) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const currentRoute = Object.values(Routes).find(
    (route) => route.path === currentPath
  );

  const baseClassName = "PageTitle";

  const Jsx = ({ title }: { title: string }) => (
    <Container className={baseClassName}>
      <Text tag="h2" className={`${baseClassName}__title`}>
        {title}
      </Text>
    </Container>
  );

  if (title) return <Jsx title={title} />;
  if (!currentRoute || !currentRoute.showTitle) {
    return null;
  }

  return <Jsx title={currentRoute.label} />;
};

export default PageTitle;
