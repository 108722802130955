import { NavLink } from "react-router-dom";

// Constants
import { Routes } from "../../constants/Routes.constants";

// Types
import { JobType } from "../../types/Job.type";

// Utils
import { generateUrlPath } from "../../utils/utils";

// components
import {
  Text,
} from "../../design_system/components";

// Styles
import "./JobsListItem.scss";

type Props = {
  job: JobType;
};

const JobsListItem = ({ job }: Props) => {
  const path = generateUrlPath({
    path: Routes.JOBS_DETAILS_PAGE.path,
    params: {
      id: job.sys.id,
    },
  });

  const baseClassName = "JobsListItem";
  return (
    <NavLink to={path} className={baseClassName}>
      <Text tag="h5">{job.title}</Text>
    </NavLink>
  );
};

export default JobsListItem;
