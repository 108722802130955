// Hooks
import { useBanner } from "../../hooks/use-banner";

// Components
import { BodsBanner } from "../../design_system/components";

const Banner = () => {
  const { hasBanner, banner } = useBanner();

  if (!hasBanner) return null;
  return (
    <BodsBanner
      text={banner.text}
      linkText={banner.linkText}
      link={banner.link}
    />
  );
};

export default Banner;
