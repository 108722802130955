import "./BodsInlineError.scss";

export interface InlineErrorProps {
  /** Content briefly explaining how to resolve the invalid form field input. */
  message: string;
  /** Unique identifier of the invalid form field that the message describes */
  fieldID: string;
}

export function BodsInlineError({ message, fieldID }: InlineErrorProps) {
  if (!message) {
    return null;
  }

  return (
    <div id={errorTextID(fieldID)} className="BodsInlineError">
      {message}
    </div>
  );
}

export function errorTextID(id: string) {
  return `${id}Error`;
}
