// Images
import HeroImage from "../../images/brewery_8.png";

// components
import { Container, Row, Column } from "../../design_system/components";
import BodsImageCard from "../../design_system/components/image-card/BodsImageCard";

const AboutPage = () => {
  const baseClassName = "AboutPage";

  return (
    <div className={baseClassName}>
      <Container>
        <Row>
          <Column size={12}>
            <BodsImageCard image={HeroImage}>
              <div style={{ padding: "250px 0" }}></div>
            </BodsImageCard>
          </Column>
        </Row>
      </Container>
      <Container paddingY={3}>
        <Row>
          <Column size={12}>About page</Column>
        </Row>
      </Container>
    </div>
  );
};

export default AboutPage;
