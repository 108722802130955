// Hooks
import { useFoodTruckCalendar } from "../../hooks/use-food-truck-calendar";
import { useResponsiveScreenWidth } from "../../design_system/hooks/use-responsive-screen-width";

// Components
import { Container, BodsEmptyState } from "../../design_system/components";
import EventCardList from "../EventCardList/EventCardList";
import EventCalendar from "../EventCalendar/EventCalendar";
import CalendarFoodTruckCard from "./CalendarFoodTruckCard";

const FoodTruckPage = () => {
  const { isLoading, data = [] } = useFoodTruckCalendar();
  const { isLarge } = useResponsiveScreenWidth({ isAtLeast: true });

  const showEmpty = !isLoading && data?.length === 0;

  const baseClassName = "FoodTruckPage";

  return (
    <>
      <div className={baseClassName}>
        <Container paddingY={2}>
          {isLoading && <>Loading...</>}
          {showEmpty ? (
            <BodsEmptyState
              title="No food trucks scheduled at this time"
              text="Check back often, we're constantly updating our schedule"
              showBorder={false}
            />
          ) : (
            <>
              {isLarge ? (
                <EventCalendar
                  events={data}
                  eventComponent={CalendarFoodTruckCard}
                />
              ) : (
                <EventCardList events={data} />
              )}
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default FoodTruckPage;
