import React from "react";
import classnames from "classnames";

import "./BodsModalBody.scss";

export interface Props {
  children?: React.ReactNode;
  size?: string;
}

const BodsModalBody: React.FC<Props> = ({ children, size }: Props) => {
  const classNamePrefix = "BodsModalBody";

  const classes = classnames(classNamePrefix, `${classNamePrefix}--${size}`);
  return <div className={classes}>{children}</div>;
};

export default BodsModalBody;
