import React, { useCallback, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

// Components
import { Portal, BodsBackdrop, BodsButton, BodsIcon } from "../";

// Styles
import "./BodsDrawer.scss";

type Props = {
  isOpen: boolean;
  toggleIsOpen: () => void;
  title?: string;
  children?: React.ReactNode;
};

const bodyClassNamePrefix = "Body--BodsDrawer-opened";

const BodsDrawer = ({ isOpen, toggleIsOpen, title, children }: Props) => {
  const removeStylesFromBody = useCallback(() => {
    document.body.classList.remove(bodyClassNamePrefix);
    document.body.style.paddingRight = "unset";
  }, []);

  const addStylesToBody = useCallback(() => {
    const prevWidth = document.body.clientWidth;
    document.body.classList.add(bodyClassNamePrefix);
    const currentWidth = document.body.clientWidth;
    document.body.style.paddingRight = `${currentWidth - prevWidth}px`;
  }, []);

  useEffect(() => {
    if (isOpen) addStylesToBody();
    else removeStylesFromBody();

    return removeStylesFromBody;
  }, [isOpen, addStylesToBody, removeStylesFromBody]);

  const baseClassName = "BodsDrawer";

  return (
    <Portal idPrefix="drawer">
      <div className={baseClassName}>
        <CSSTransition
          in={isOpen}
          timeout={350}
          classNames={`${baseClassName}__animation`}
          unmountOnExit
        >
          <div className={`${baseClassName}__content__wrapper`}>
            <div className={`${baseClassName}__content`}>
              <div className={`${baseClassName}__content__header`}>
                <p className={`${baseClassName}__content__header__title`}>
                  {title}
                </p>
                <div className={`${baseClassName}__content__header__close-btn`}>
                  <BodsButton color="tertiary" onClick={toggleIsOpen}>
                    <BodsIcon name="close" />
                  </BodsButton>
                </div>
              </div>

              <div className={`${baseClassName}__content__body`}>
                {children}
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
      {isOpen && <BodsBackdrop onClick={toggleIsOpen} />}
    </Portal>
  );
};

export default BodsDrawer;
