const prefix = "outline";
const iconName = "globe";
const viewBox = "0 0 22 22";

const svg = (color) => (
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
  <g transform="translate(-173.000000, -253.000000)">
      <g transform="translate(172.000000, 252.000000)">
          <g>
              <g>
                  <path d="M12,22 C6.47715235,22 2,17.5228477 2,12 C2,6.47715235 6.47715235,2 12,2 C17.5228477,2 22,6.47715235 22,12 C22,17.5228477 17.5228477,22 12,22 Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0,0"></path>
                  <path d="M7.99997562,3 L8.99997568,3 C7.04997563,8.84000015 7.04997563,15.1599998 8.99997568,21 L7.99997562,21" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0,0" fillRule="nonzero"></path>
                  <path d="M15,3 C16.95,8.84000015 16.95,15.1599998 15,21" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0,0" fillRule="nonzero"></path>
                  <path d="M3,16 L3,15 C8.84000015,16.95 15.1599998,16.95 21,15 L21,16" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0,0" fillRule="nonzero"></path>
                  <path d="M3,9.00009775 C8.84000015,7.0500977 15.1599998,7.0500977 21,9.00009775" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0,0" fillRule="nonzero"></path>
                  <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" opacity="0" transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) "></path>
              </g>
          </g>
      </g>
  </g>
</g>
);

export const globe = {
  prefix,
  iconName, // Any name you like
  svg, // svg path data
  viewBox,
};

