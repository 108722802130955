import { BodsCalendarEventType } from "../../design_system/components/calendar";
import { useEventsCalendar } from "../../hooks/use-events-calendar";

// Constants
import { Routes } from "../../constants/Routes.constants";

// Components
import EventCard from "../EventCard/EventCard";

type Props = {
  event: BodsCalendarEventType;
};

const CalendarEventCard = ({ event }: Props) => {
  const { data = [] } = useEventsCalendar();

  const eventData = data && data.find((e) => e.sys.id === event.id)!;

  return (
    <EventCard
      event={eventData}
      slim
      route={Routes.EVENT_DETAILS_PAGE}
      bordered={false}
      showShortDescription
    />
  );
};

export default CalendarEventCard;
