// <?xml version="1.0" encoding="UTF-8"?>
// <svg width="9px" height="18px" viewBox="0 0 9 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
//     <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
//     <title>vuesax/outline/arrow-right</title>
//     <desc>Created with Sketch.</desc>
//     <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
//         <g id="vuesax/outline/arrow-right" transform="translate(-8.000000, -3.000000)">
//             <g id="arrow-right">
//                 <g>
//                     <path d="M8.37960938,20.4500198 C8.08960938,20.1600198 8.08960938,19.6800185 8.37960938,19.3900185 L14.8996091,12.8700199 C15.3796091,12.3900199 15.3796091,11.6100197 14.8996091,11.1300197 L8.37960938,4.61001968 C8.08960938,4.32001969 8.08960938,3.84001952 8.37960938,3.55001953 C8.66960937,3.26001954 9.14960882,3.26001954 9.43960881,3.55001953 L15.9596095,10.0700192 C16.4696095,10.5800192 16.7596092,11.27002 16.7596092,12.00002 C16.7596092,12.73002 16.4796095,13.4200203 15.9596095,13.9300203 L9.43960881,20.4500198 C9.28960881,20.5900198 9.09960908,20.6700191 8.90960908,20.6700191 C8.71960908,20.6700191 8.52960938,20.6000198 8.37960938,20.4500198 Z" id="Vector" fill="#292D32" fill-rule="nonzero"></path>
//                     <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" id="Vector" opacity="0"></path>
//                 </g>
//             </g>
//         </g>
//     </g>
// </svg>

const prefix = "outline";
const iconName = "chevron-right";
const viewBox = "0 0 9 18";

const svg = (color) => (
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-8.000000, -3.000000)">
      <g>
        <g>
          <path
            d="M8.37960938,20.4500198 C8.08960938,20.1600198 8.08960938,19.6800185 8.37960938,19.3900185 L14.8996091,12.8700199 C15.3796091,12.3900199 15.3796091,11.6100197 14.8996091,11.1300197 L8.37960938,4.61001968 C8.08960938,4.32001969 8.08960938,3.84001952 8.37960938,3.55001953 C8.66960937,3.26001954 9.14960882,3.26001954 9.43960881,3.55001953 L15.9596095,10.0700192 C16.4696095,10.5800192 16.7596092,11.27002 16.7596092,12.00002 C16.7596092,12.73002 16.4796095,13.4200203 15.9596095,13.9300203 L9.43960881,20.4500198 C9.28960881,20.5900198 9.09960908,20.6700191 8.90960908,20.6700191 C8.71960908,20.6700191 8.52960938,20.6000198 8.37960938,20.4500198 Z"
            fill={color}
            fillRule="nonzero"
          ></path>
          <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" opacity="0"></path>
        </g>
      </g>
    </g>
  </g>
);

export const chevronRight = {
  prefix,
  iconName, // Any name you like
  svg, // svg path data
  viewBox,
};
